import { type Amount } from '../../../common/amount'

export enum ANALYTICS_EVENTS {
  page_view,
  bookingApp_startQuotesRequest,
  bookingApp_quotesRequested,
  bookingApp_moveCreated,
  bookingApp_agentBooked,
  bookingApp_quoteSelected,
  bookingApp_quoteUnselected,
  bookingApp_quoteAccepted,
  bookingApp_PromoCodeAdded,
  bookingApp_PromoCodeRemoved,
}

export enum FACEBOOK_EVENTS {
  CompleteRegistration,
  Schedule,
  InitiateCheckout,
  Purchase,
}

export type NewMoveParams = {
  nbQuotesReceived: number
  cheapestPriceReceived?: Amount
  fromCity?: string
  fromRegion?: string
  toCity?: string
  toRegion?: string
}

export type QuotesSelectedEventParams = {
  companyId: string
  companyName: string
  price: Amount
  companyCity?: string
  position: number
}

export type QuotesAcceptedEventParams = {
  companyId: string
  companyName: string
  price: Amount
  companyCity?: string
  promoCode?: string
}
