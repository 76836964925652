import { useTranslation } from 'react-i18next'
import ClockIcon from '@mui/icons-material/AccessTimeOutlined'

import { useSelectedQuote } from '../../../modules/moves'
import { Select } from '../../Form'
import { InputSize } from '../../Form/controls/Input'

type TimeControlProps = {
  name: string
}

const TimeControl: React.FC<TimeControlProps> = ({
  name,
}) => {
  const { t } = useTranslation()
  const selectedQuote = useSelectedQuote()

  if (!selectedQuote) {
    return null
  }

  const timeOptions = Object.values(selectedQuote.companyBranch.services?.arrivalWindows ?? {})
    .map(arrivalWindow => ({
      value: arrivalWindow,
      label: t(`arrivalWindows.${arrivalWindow}.title`),
    }))

  return (
    <div className="w-full sm:!w-[350px]">
      <Select
        name={name}
        options={timeOptions}
        startNode={<ClockIcon className="!text-[18px] text-neutral-500" />}
        size={InputSize.Small}
      />
    </div>
  )
}

export default TimeControl
