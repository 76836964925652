import { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useCurrentMove } from '../../../modules/moves'
import { useQueryString } from '../../../modules/navigation'
import { capitalizeFirstLetter } from '../../../utils/string'
import Form, { StarsRating, SubmitButton, Textarea } from '../../Form'
import useValidationSchema from './validationSchema'
import { type RatingFormValues } from './RatingForm.type'
import { formatFormOutput } from './formDataBuilder'

type RatingFormProps = {
  onSubmit: (values: any) => Promise<void> | void
}

const RatingForm: React.FC<RatingFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const move = useCurrentMove()
  const mounted = useRef(false)

  const form = useForm<RatingFormValues>({
    resolver: yupResolver(schema),
  })

  const ratingParam = useQueryString('rating')
  const validRatingParam = /^[0-9]{1}$/.test(ratingParam) &&
    parseInt(ratingParam) > 0 &&
    parseInt(ratingParam) <= 5
  const rating = validRatingParam ? parseInt(ratingParam) : undefined

  /**
   * apply default rating value
   * this is used for emails. They render 5 stars and each stars is using ?rating=XX
   */
  useEffect(() => {
    if (mounted.current || !rating) {
      return
    }
    mounted.current = true
    form.setValue('overallRating', String(rating))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating])

  const anonymizedName = [
    capitalizeFirstLetter(move.user.firstName),
    `${move.user.lastName[0].toLocaleUpperCase()}.`,
  ].join(' ')

  const AnonymizedName = () => <strong>{ anonymizedName }</strong>

  const subRatings = {
    professionalismRating: t('forms.ratingForm.subQuestions.professionalism'),
    punctualityRating: t('forms.ratingForm.subQuestions.punctuality'),
    communicationRating: t('forms.ratingForm.subQuestions.communication'),
  }

  return (
    <Form
      form={form}
      onSubmit={async value => { await onSubmit(formatFormOutput(value)) }}
    >
      <div>
        <div className="font-body2 text-xl text-neutral-700">
          { t('forms.ratingForm.overallRatingLabel') }
        </div>

        <StarsRating name="overallRating" />

        <div className="my-8 flex flex-col gap-y-6 rounded-2xl bg-neutral-50 py-6">
          { Object.entries(subRatings).map(([name, label]) => (
            <div key={name}>
              <div className="font-body2 text-lg text-neutral-500">
                { label }
              </div>

              <StarsRating name={name} small />
            </div>
          )) }
        </div>

        <div className="mb-8">
          <label>
            <div className="font-body2 text-xl text-neutral-700">
              { t('forms.ratingForm.review.question') }
            </div>
            <div className="mb-4 font-body2 text-sm text-neutral-500">

              <Trans i18nKey="forms.ratingForm.review.publishedAs">
                <AnonymizedName />
              </Trans>
            </div>

            <div className="flex w-full justify-center">
              <div className="w-full max-w-2xl ">
                <Textarea
                  name="review"
                  placeholder={t('forms.ratingForm.review.placeholder')}
                  className="child:child:placeholder:!text-center"
                  minRows={3}
                  inputProps={{
                    maxLength: 4096,
                  }}
                />
              </div>
            </div>
          </label>
        </div>

        <SubmitButton>
          { t('actions.send') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default RatingForm
