import { type CompanyRating } from '../../../modules/companyRatings'
import { type PublicCompanyBranch } from '../../../modules/companies'
import TimeAgo from '../../TimeAgo'
import StarsRating from '../StarsRating'
import CustomerAvatar from './CustomerAvatar'
import OwnerResponse from './OwnerResponse'
import { mergeClassName } from '../../../utils/mergeClassName'

type RatingProps = {
  rating: CompanyRating
  companyBranch: PublicCompanyBranch
  hideOwnerResponse?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  children?: React.ReactNode
}

const Rating: React.FC<RatingProps> = ({
  rating,
  companyBranch,
  hideOwnerResponse = false,
  onClick,
  children,
}) => {
  const hasResponse = !!rating.ownerResponse

  return (
    <div
      className="mb-10"
    >
      <div className="flex items-center gap-x-4">
        <div
          onClick={onClick}
          className={mergeClassName(
            onClick && 'child:cursor-pointer cursor-pointer',
          )}
        >
          <CustomerAvatar
            rating={rating}
          />
        </div>

        <div className="flex flex-col">
          <div
            className="text-base font-bold leading-tight text-neutral-700"
            onClick={onClick}
          >
            <span className={mergeClassName(
              onClick && 'underline cursor-pointer',
            )}
            >
              { rating.customerName }
            </span>
          </div>
          <div className="flex items-center gap-x-2">
            <StarsRating rating={rating.overallRating} />
            <div className="lowercase text-neutral-500">
              <TimeAgo date={rating.createdAt} />
            </div>
          </div>
        </div>
      </div>

      { rating.review && (
        <div className="whitespace-pre-wrap pl-1 pt-2 text-base leading-5 text-zinc-600">
          { rating.review }
        </div>
      ) }

      { hasResponse
        ? (
          <OwnerResponse
            rating={rating}
            companyBranch={companyBranch}
            hideContent={hideOwnerResponse}
          >
            { children }
          </OwnerResponse>
          )
        : children }
    </div>
  )
}

export default Rating
