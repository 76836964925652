import { useExperimentGroup } from '../../modules/abTesting'
import { ExperimentProvider } from './Experiment.context'

type ExperimentProps = {
  children?: React.ReactNode
}

const Experiment: React.FC<ExperimentProps> = ({
  children,
}) => {
  const group = useExperimentGroup()
  return (
    <ExperimentProvider group={group ?? 'a'}>
      { children }
    </ExperimentProvider>
  )
}

export default Experiment
