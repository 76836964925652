import { useTranslation } from 'react-i18next'

import { Heading, Subtitle } from '../../../components/typography'
import FullWidthPage from '../../../components/layout/FullWidthPage'
import { PageContent } from '../../../components/Container'
import { useCurrentMove } from '../../../modules/moves'
import MoveCalendly from './MoveCalendly'

const MoveNoQuotesPage: React.FC = () => {
  const { t } = useTranslation()
  const move = useCurrentMove()

  return (
    <FullWidthPage>
      <PageContent>

        <div className="pt-4 text-center">
          <Heading variant="h1">
            { t('pages.move.noQuotes.title') }
          </Heading>
          <Subtitle className="lg:text-center">
            { t('pages.move.noQuotes.subtitle') }
          </Subtitle>

          <MoveCalendly move={move} />
        </div>

      </PageContent>
    </FullWidthPage>
  )
}

export default MoveNoQuotesPage
