import { useCallback, useEffect } from 'react'

import { useConfig } from '../../config'
import { charToNumber, numberToChar } from './abTesting.utils'

const localStoragePrefix = 'ex'

/**
 * returns the current user's group for the experiment
 */
export const useExperimentGroup = (): string | null => {
  const experimentName = useConfig<string | undefined>('abTesting.experimentName')
  const nbVariants = useConfig<number | undefined>('abTesting.nbVariants')
  const debug = !(useConfig('isProduction') ?? true)
  const enabled = !!experimentName && !!nbVariants

  if (!enabled) {
    return null
  }

  const localStorageKey = `${localStoragePrefix}.${experimentName}`
  const storedGroup = localStorage.getItem(localStorageKey)

  if (storedGroup !== null && charToNumber(storedGroup) <= nbVariants) {
    return storedGroup
  }

  if (debug) {
    return 'a'
  }

  const newGroup = numberToChar(Math.floor(Math.random() * nbVariants) + 1)
  localStorage.setItem(localStorageKey, newGroup)
  return newGroup
}

/**
 * hook to debug the experiment group
 */
export const useDebugExperimentGroup = () => {
  const experimentName = useConfig<string | undefined>('abTesting.experimentName')
  const nbVariants = useConfig<number | undefined>('abTesting.nbVariants')
  const debug = !(useConfig('isProduction') ?? true)
  const enabled = !!experimentName && !!nbVariants
  const localStorageKey = `${localStoragePrefix}.${String(experimentName)}`
  const group = useExperimentGroup()

  /**
   * set the experiment group and reload the page
   */
  const setGroup = useCallback((groupIdentifier: number) => {
    const newGroup = numberToChar(groupIdentifier)
    localStorage.setItem(localStorageKey, newGroup)
    document.location.reload()
  }, [localStorageKey])

  /**
   * set the next group (ex: A, B, C, A, B, ...)
   */
  const nextGroup = () => {
    if (!group || !nbVariants) {
      return
    }
    const groupIdentifier = charToNumber(group)
    setGroup((groupIdentifier % nbVariants) + 1)
  }

  /**
   * if enabled, we listen to the keyboard to change the group
   */
  useEffect(() => {
    if (!debug || !enabled || !group) {
      return
    }

    const onKeyDown = (event: KeyboardEvent) => {
      const control = event.ctrlKey || event.metaKey
      const alt = event.altKey

      if (
        !control ||
        !alt ||
        !/^[1-9]$/.test(event.key) ||
        parseInt(event.key) > nbVariants
      ) {
        return
      }
      setGroup(parseInt(event.key))
    }

    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [debug, enabled, group, nbVariants, localStorageKey, setGroup])

  return {
    setGroup,
    nextGroup,
  }
}
