import { useTranslation } from 'react-i18next'

import { ReactComponent as RedArrow } from '../../../assets/images/red-arrow-1.svg'
import { Experiment, Variant } from '../../../components/Experiment'

const ProgressBarDescription: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Experiment>
      <Variant group="c">
        <div className="relative">
          <div className="absolute right-0 top-0 pr-[35px] pt-[5px] text-right font-body2 text-xs text-neutral-500 xs:pr-[40px] xs:pt-[2px] xs:text-base">
            { t('forms.quotesRequest.yourPricesInLessThanFiveMinutes') }
            <RedArrow
              className="absolute right-0 top-0 mr-[-10px] mt-[-15px] size-[50px]"
            />
          </div>
        </div>
      </Variant>
    </Experiment>
  )
}

export default ProgressBarDescription
