import { type BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

type NewRelicLogLevel = 'INFO' | 'WARN' | 'ERROR'

const globalNewRelic: any = (window as any).newrelic
const newRelic: BrowserAgent | undefined = globalNewRelic?.init ? globalNewRelic : undefined

const TWO_SEC_MS = 2 * 1000

export const error = (error: Error, debug?: any) => {
  if (!newRelic) {
    return
  }
  try {
    newRelic.noticeError(error, debug)
  } catch (error) {
    console.error(error)
  }
}

const log = (message: string, level: NewRelicLogLevel, debug?: any) => {
  if (!newRelic) {
    return
  }
  try {
    newRelic.log(message, {
      level,
      customAttributes: debug,
    })
  } catch (error) {
    console.error(error)
  }
}

export const setApplicationVersion = (version: string) => {
  if (!newRelic) {
    return
  }
  try {
    newRelic.setApplicationVersion(version)
  } catch (error) {
    console.error(error)
  }
}

export const logInfo = (message: string, debug?: any) => { log(message, 'INFO', debug) }
export const logWarn = (message: string, debug?: any) => { log(message, 'WARN', debug) }
export const logErro = (message: string, debug?: any) => { log(message, 'ERROR', debug) }
