import { useTranslation } from 'react-i18next'

import { type PublicCompanyBranch } from '../../../modules/companies'
import { type CompanyRating } from '../../../modules/companyRatings'
import { useCompanyBranchName } from '../../../modules/companies'
import CompanyAvatar from './CompanyAvatar'

type OwnerResponseProps = {
  rating: CompanyRating
  companyBranch: PublicCompanyBranch
  hideContent?: boolean
  children?: React.ReactNode
}

const OwnerResponse: React.FC<OwnerResponseProps> = ({
  rating,
  companyBranch,
  hideContent = false,
  children,
}) => {
  const { t } = useTranslation()
  const companyName = useCompanyBranchName(companyBranch)

  if (!hideContent && !rating.ownerResponse) {
    return null
  }

  return (
    <div className="ml4 mt-4 flex flex-col rounded-xl bg-gray-50 p-6 md:ml-8">
      <div className="flex items-center gap-x-4">

        <CompanyAvatar companyBranch={companyBranch} />

        <div>
          <div className="text-sm font-bold leading-none text-neutral-500">
            { t('components.companyBranchRatings.rating.responseFrom') }
          </div>
          <div className="text-base font-bold leading-tight text-neutral-700">
            { companyName }
          </div>
        </div>
      </div>

      { !hideContent && (
        <div className="whitespace-pre-wrap pl-1 pt-2 text-base leading-5 text-zinc-600">
          { rating.ownerResponse }
        </div>
      ) }

      { children }
    </div>
  )
}

export default OwnerResponse
