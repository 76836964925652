import { useTranslation } from 'react-i18next'

import {
  type UpdateMovePayload,
  useCurrentMove,
  useUpdateMoveAction,
} from '../../../modules/moves'
import { formatPhoneNumber } from '../../../common/phoneNumber'
import { type EditableSectionProps } from '../../EditableSections/editableSections.type'
import {
  Label,
  Value,
  Row,
  Cell,
} from '../../data'
import IdentificationForm from '../../forms/editmove/IdentificationForm'
import { errorNotification, successNotification } from '../../ToastNotifications'
import { useErrorFormatter } from '../../errors/useErrorFormatter'

const IdentificationSection: React.FC<EditableSectionProps> = ({
  isEditing,
  onClose,
  wide,
}) => {
  const { t } = useTranslation()
  const move = useCurrentMove()
  const [updateMove] = useUpdateMoveAction()
  const formatError = useErrorFormatter()

  const onSubmit = async (data: UpdateMovePayload) => {
    try {
      await updateMove(data)
      successNotification(t('components.move.identification.success'))
      onClose()
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (isEditing) {
    return (
      <IdentificationForm
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    )
  }

  return (
    <>
      <Row>
        <Cell>
          <Label>
            { t('components.move.identification.firstName') }
          </Label>
          <Value>
            { move.user.firstName }
          </Value>
        </Cell>

        <Cell>
          <Label>
            { t('components.move.identification.lastName') }
          </Label>
          <Value>
            { move.user.lastName }
          </Value>
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label>
            { t('components.move.identification.email') }
          </Label>
          <Value>
            { move.user.email }
          </Value>
        </Cell>

        <Cell>
          <Label>
            { t('components.move.identification.phoneNumber') }
          </Label>
          <Value>
            { formatPhoneNumber(move.user.phoneNumber) }
          </Value>
        </Cell>
      </Row>
    </>
  )
}

export default IdentificationSection
