import { useExperimentContext } from './Experiment.context'

type VariantProps = {
  group: string
  children?: React.ReactNode
}

const Variant: React.FC<VariantProps> = ({ group: forGroup, children }) => {
  const { group } = useExperimentContext()
  if (group !== forGroup) {
    return null
  }
  return children ?? null
}

export default Variant
