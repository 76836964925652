import {
  type ActionOptions,
  useAction,
  useActionWithPayload,
} from '../../graphQl'
import { useAnalytics } from '../../analytics'
import {
  type Quote,
  useSortedQuote,
} from '../../quotes'
import { getCheapestQuotePrice } from '../../quotes/core/utils/quotes.utils'
import Language from '../../../app/Language.enum'
import {
  useCurrentMove,
  useSelectedQuote,
} from './moves.data.hooks'
import { type Move } from './moves.models'
import {
  type CreateMovePayload,
  type UpdateMovePayload,
  type AcceptQuotePayload,
} from './moves.types'
import * as mutations from './moves.mutations'

/**
 * create a move
 */
export const useCreateMoveAction = () => {
  const [mutate, data] = useActionWithPayload(
    useAction<Move>(mutations.createMove),
    (payload: CreateMovePayload) => ({ payload }),
  )
  const { pushMoveCreatedEvent } = useAnalytics()

  const action = async (payload: CreateMovePayload) => {
    const move = await mutate(payload)

    /* tracking */

    const nbQuotesReceived = move.quotes?.length ?? 0
    const cheapestPriceReceived = getCheapestQuotePrice(move)
    const fromCity = 'city' in move.origin.address ? move.origin.address?.city : undefined
    const fromRegion = 'region' in move.origin.address ? move.origin.address?.region : undefined
    const toCity = 'city' in move.destination.address ? move.destination.address?.city : undefined
    const toRegion = 'region' in move.destination.address ? move.destination.address?.region : undefined

    pushMoveCreatedEvent({
      nbQuotesReceived,
      cheapestPriceReceived,
      fromCity,
      fromRegion,
      toCity,
      toRegion,
    })

    return move
  }
  return [action, data] as [typeof action, typeof data]
}

/**
 * update a move
 */
export const useUpdateMoveAction = () => {
  const move = useCurrentMove()
  const { pushAgentBookedEvent } = useAnalytics()

  const [mutate, data] = useActionWithPayload(
    useAction<Move>(mutations.updateMove),
    (payload: UpdateMovePayload) => ({
      moveId: move.id,
      payload,
    }),
  )
  const action = async (payload: UpdateMovePayload) => {
    const move = await mutate(payload)

    /* tracking  */

    if (payload.calendlyScheduledEventUri) {
      pushAgentBookedEvent()
    }

    return move
  }
  return [action, data] as [typeof action, typeof data]
}

/**
 * refresh quotes
 */
export const useRefreshQuotesAction = () => {
  const move = useCurrentMove()

  return useActionWithPayload(
    useAction<Move>(mutations.refreshQuotes),
    () => ({ moveId: move.id }),
  )
}

/**
 * select/unselect a quote
 */
export const useSelectQuoteAction = () => {
  const move = useCurrentMove()
  const quotes = useSortedQuote(move.quotes ?? [])
  const { pushQuoteSelectedEvent, pushQuoteUnselectedEvent } = useAnalytics()

  const [mutate, data] = useActionWithPayload(
    useAction<Move>(mutations.selectQuote),
    (quote: Quote | null) => ({
      moveId: move.id,
      branchId: quote?.companyBranch.id ?? null,
    }),
  )

  const action = async (quote: Quote | null) => {
    const move = await mutate(quote)

    /* tracking */
    if (quote) {
      const position = (quotes?.findIndex(({ id }) => id === quote.id) ?? 0) + 1
      pushQuoteSelectedEvent({
        companyId: quote.companyBranch.id,
        companyName: quote.companyBranch.identification?.name[Language.En] ??
        quote.companyBranch.company.identification.name[Language.En],
        price: quote.subtotal,
        companyCity: quote.companyBranch.tripAndTravel?.trucksOriginAddress.city,
        position,
      })
    } else {
      pushQuoteUnselectedEvent()
    }

    return move
  }

  return [action, data] as [typeof action, typeof data]
}

/**
 * prepare move deposit
 */
export const usePrepareMoveDepositAction = () => {
  const move = useCurrentMove()

  return useActionWithPayload(
    useAction<Move>(mutations.prepareMoveDeposit),
    () => ({ moveId: move.id }),
  )
}

/**
 * prepare move deposit
 */
export const usePrepareMoveBalancePaymentAction = () => {
  const move = useCurrentMove()

  return useActionWithPayload(
    useAction<Move>(mutations.prepareMoveBalancePayment),
    () => ({ moveId: move.id }),
  )
}

/**
 * select/unselect a quote
 */
export const useApplyPromoCodeAction = () => {
  const move = useCurrentMove()
  const { pushPromoCodeAddedEvent, pushPromoCodeRemovedEvent } = useAnalytics()

  const [mutate, data] = useActionWithPayload(
    useAction<Move>(mutations.applyPromoCode),
    (promoCode: string | null) => ({
      moveId: move.id,
      promoCode,
    }),
  )

  const action = async (promoCode: string | null) => {
    const originalPromoCode = move.promoCode?.code
    const updatedMove = await mutate(promoCode)
    const newPromoCode = updatedMove.promoCode?.code

    /* tracking */

    if (promoCode && newPromoCode) {
      pushPromoCodeAddedEvent(newPromoCode)
    } else if (!promoCode && originalPromoCode) {
      pushPromoCodeRemovedEvent(originalPromoCode)
    }

    return move
  }

  return [action, data] as [typeof action, typeof data]
}

/**
 * accept a quote
 */
export const useAcceptQuoteAction = (options?: ActionOptions) => {
  const move = useCurrentMove()
  const selectedQuote = useSelectedQuote()
  const { pushQuoteAcceptedEvent } = useAnalytics()

  const [mutate, data] = useActionWithPayload(
    useAction<Move>(mutations.acceptQuote, options),
    (payload: AcceptQuotePayload) => ({
      moveId: move.id,
      payload,
    }),
  )

  const action = async (
    payload: AcceptQuotePayload,
  ) => {
    if (!selectedQuote) {
      throw new Error('No selected quote')
    }

    const move = await mutate(payload)

    /* tracking */
    pushQuoteAcceptedEvent({
      companyId: selectedQuote.companyBranch.id,
      companyName: selectedQuote.companyBranch.identification?.name[Language.En] ??
  selectedQuote.companyBranch.company.identification.name[Language.En],
      price: selectedQuote.subtotal,
      companyCity: selectedQuote.companyBranch.tripAndTravel?.trucksOriginAddress.city,
      promoCode: move.promoCode?.code,
    })

    return move
  }

  return [action, data] as [typeof action, typeof data]
}

/**
 * upload a note attachment
 */
export const useUploadNoteAttachmentAction = () => {
  const move = useCurrentMove()

  return useActionWithPayload(
    useAction<Move>(mutations.uploadNoteAttachment),
    (file: File) => ({
      moveId: move.id,
      payload: { file },
    }),
  )
}

/**
 * delete a note attachment
 */
export const useDeleteNoteAttachmentAction = () => {
  const move = useCurrentMove()

  return useActionWithPayload(
    useAction<Move>(mutations.deleteNoteAttachment),
    (fileId: string) => ({
      moveId: move.id,
      payload: { fileId },
    }),
  )
}
