import { SortDirection, type PaginatedQuery } from '../../graphQl'
import { RatingSorting } from './companyRatings.enums'

export const getCompanyRatingQuery = (sorting: RatingSorting): PaginatedQuery => {
  switch (sorting) {
    case RatingSorting.MostRecent:
      return {
        nbResultsPerPage: 25,
        sortField: 'createdAt',
        sortDirection: SortDirection.DESC,
      }

    case RatingSorting.HighestRating:
      return {
        nbResultsPerPage: 25,
        sortField: 'overallRating',
        sortDirection: SortDirection.DESC,
      }

    case RatingSorting.LowestRating:
      return {
        nbResultsPerPage: 25,
        sortField: 'overallRating',
        sortDirection: SortDirection.ASC,
      }
  }
}
