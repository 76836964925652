import { useTranslation } from 'react-i18next'

import { mergeClassName } from '../../utils/mergeClassName'

type EndMessageProps = {
  totalRating: number
  availablesRatings: number
  small?: boolean
}
const EndMessage: React.FC<EndMessageProps> = ({
  totalRating,
  availablesRatings,
  small = false,
}) => {
  const { t } = useTranslation()

  if (totalRating === 0) {
    return (
      <div
        className={mergeClassName(
          'rounded-lg border bg-neutral-50 text-lg p-8 text-center',
          !small && 'xl:-mr-4',
        )}
      >
        { t('components.companyBranchRatings.endMessage.noReviews') }
      </div>
    )
  }

  return null
}

export default EndMessage
