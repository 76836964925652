import { type PublicCompanyBranch } from '../../../modules/companies'
import { useLanguage } from '../../../modules/i18n'
import Language from '../../../app/Language.enum'
import { useCompanyBranchName } from '../../../modules/companies'

type CompanyAvatarProps = {
  companyBranch: PublicCompanyBranch
}

const CompanyAvatar: React.FC<CompanyAvatarProps> = ({
  companyBranch,
}) => {
  const language = useLanguage()
  const companyName = useCompanyBranchName(companyBranch)

  const selectedCompanyLogo = companyBranch.company?.logo?.[language as Language] ??
  companyBranch.company?.logo?.[Language.En]

  if (!companyName) {
    return null
  }

  return (
    <div className="flex size-[60px] shrink-0 cursor-default items-center justify-center overflow-hidden rounded-full border border-neutral-300 bg-white p-1 font-body2 text-xl font-bold uppercase text-neutral-500 shadow-lg">
      { selectedCompanyLogo
        ? (
          <img
            src={selectedCompanyLogo}
            alt={companyName}
          />
          )
        : (
            companyName[0]
          ) }
    </div>
  )
}

export default CompanyAvatar
