export enum ReviewApprobation {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum RatingSorting {
  MostRecent = 'MostRecent',
  HighestRating = 'HighestRating',
  LowestRating = 'LowestRating',
}
