import { useTranslation } from 'react-i18next'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'
import PlaceIcon from '@mui/icons-material/PlaceOutlined'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import FridgeIcon from '@mui/icons-material/KitchenOutlined'
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight'
import ManIcon from '@mui/icons-material/Person'
import TruckIcon from '@mui/icons-material/LocalShipping'
import TimerIcon from '@mui/icons-material/Timer'
import EditIcon from '@mui/icons-material/Edit'

import { useCurrentMove } from '../../modules/moves'
import { Room, SpecialItem } from '../../modules/quotes'
import { useNavigate } from '../../modules/navigation'
import { dateInTimezone, useDateFormatter } from '../../utils/date'
import { mergeClassName } from '../../utils/mergeClassName'
import { capitalizeFirstLetter } from '../../utils/string'
import Value from './Value'
import { type Place } from '../../common/place'
import MultiValues from './MultiValues'
import ItemsRow from './ItemsRow'
import Button, { ButtonColor, ButtonSize, ButtonVariant } from '../Button'
import Route from '../../app/Route.enum'

const MoveSummaryBanner: React.FC = () => {
  const { t } = useTranslation()
  const move = useCurrentMove()
  const formatDate = useDateFormatter()
  const { navigate } = useNavigate()

  const getAddress = (place: Place) => {
    if ('placeTextLine1' in place && place.placeTextLine1) {
      return place.placeTextLine1
    }
    if ('city' in place && 'region' in place && place.city) {
      return `${capitalizeFirstLetter(place.city) ?? ''}, ${place.region ?? ''}`
    }
    if ('postalCode' in place && place.postalCode) {
      return place.postalCode.replace(' ', '').toUpperCase()
    }
    return null
  }

  /**
   * returns a string that summarize the room of the residence
   */
  const getRoomsSummary = () => {
    if (!move.residenceRooms?.length) {
      return null
    }
    const totalRooms = move.residenceRooms.reduce((acc, curr) => acc + curr.value, 0)
    if (totalRooms === 0) {
      return null
    }
    const nbBedrooms = move.residenceRooms.find(({ key }) => key === Room.Bedroom)?.value ?? 0
    const otherRooms = Math.max(totalRooms - nbBedrooms, 0)

    if (nbBedrooms > 0 && otherRooms > 0) {
      return (
        <div className="flex flex-wrap gap-x-1">
          <div>
            { t('pages.move.quotes.rooms.bedroom', { count: nbBedrooms }) }
          </div>
          <div>
            { t('pages.move.quotes.rooms.plus', { count: otherRooms }) }
          </div>
        </div>
      )
    }
    if (nbBedrooms > 0) {
      return t('pages.move.quotes.rooms.bedroom', { count: nbBedrooms })
    }
    return t('pages.move.quotes.rooms.total', { count: otherRooms })
  }

  /**
   * returns a string that summarize the special items to move
   */
  const getSpecialItemsSummary = () => {
    if (!move.specialItems?.length) {
      return null
    }
    const totalItems = move.specialItems.reduce((acc, curr) => acc + curr.value, 0)
    if (totalItems === 0) {
      return null
    }
    const nbAppliances = move.specialItems.find(({ key }) => key === SpecialItem.Appliance)?.value ?? 0
    const otherItems = Math.max(totalItems - nbAppliances, 0)

    if (nbAppliances > 0 && otherItems > 0) {
      return (
        <div className="flex flex-wrap gap-x-1">
          <div>
            { t('pages.move.quotes.specialItems.appliance', { count: nbAppliances }) }
          </div>
          <div>
            { t('pages.move.quotes.specialItems.plus', { count: otherItems }) }
          </div>
        </div>
      )
    }
    if (nbAppliances > 0) {
      return t('pages.move.quotes.specialItems.appliance', { count: nbAppliances })
    }
    return t('pages.move.quotes.specialItems.total', { count: otherItems })
  }

  const getTrucksSummary = () => {
    return (
      <div>
        { move.nbTrucks.map(({ key, value }) => (
          <div key={key}>
            { t('nbTrucks', { count: value }) }
            { ' ' }
            ({ t(`trucks.${key}.length`) })
          </div>
        )) }
      </div>
    )
  }

  const roomsSummary = getRoomsSummary()
  const specialItemsSummary = getSpecialItemsSummary()
  const trucksSummary = getTrucksSummary()

  return (
    <div>
      <div className={mergeClassName(
        'rounded-t-3xl',
        'sm:bg-gradient-to-t from-white to-zinc-400/20',
        'border-b border-b-zinc-200 px-2 py-4 sm:py-8 text-center sm:px-4 md:px-6 lg:px-12',
      )}
      >

        <div className="flex flex-col flex-wrap items-center gap-4 xl:flex-row xl:justify-around xl:gap-0">
          <div className="flex flex-row flex-wrap justify-center gap-2 xl:flex-col">
            <ItemsRow>
              <Value
                Icon={CalendarIcon}
                label={t('pages.move.quotes.date')}
              >
                <div className="whitespace-nowrap">
                  { formatDate(dateInTimezone(move.movingDate, move.originTimezone)) }
                </div>
              </Value>

              <MultiValues
                Icon={PlaceIcon}
                separator={<ArrowIcon />}
              >
                <Value
                  label={t('pages.move.quotes.from')}
                >
                  { getAddress(move.origin.address) }
                </Value>

                <Value
                  label={t('pages.move.quotes.to')}
                >
                  { getAddress(move.destination.address) }
                </Value>
              </MultiValues>
            </ItemsRow>

            { (!!roomsSummary || !!specialItemsSummary) && (
              <ItemsRow>

                { roomsSummary && (
                  <Value
                    Icon={HomeIcon}
                    label={t('rooms.title')}
                    className="max-w-[160px] sm:max-w-none"
                  >
                    { roomsSummary }
                  </Value>
                ) }

                { specialItemsSummary && (
                  <Value
                    Icon={FridgeIcon}
                    label={t('specialItems.title')}
                  >
                    { specialItemsSummary }
                  </Value>
                ) }

              </ItemsRow>
            ) }

          </div>

          <div className="hidden h-[75px] w-px bg-neutral-300 xl:flex" />

          <div className="hidden flex-col items-center justify-center gap-x-4 sm:flex">
            <div className="mb-2 font-sans text-sm text-neutral-500 sm:mt-0">
              { t('pages.move.quotes.estimatedMoveSize') }
              { ': ' }
              <strong className="text-base">
                { move.volumeCubicFeet }
                { ' ' }
                { t('units.cubicFeet') }
              </strong>
            </div>

            <ItemsRow>
              <Value
                label={t('men')}
                Icon={ManIcon}
                inverted
              >
                { t('nbMen', { count: move.nbMen }) }
              </Value>

              <Value
                label={t('trucks.title')}
                Icon={TruckIcon}
                inverted
              >
                { trucksSummary }
              </Value>

              <Value
                Icon={TimerIcon}
                label={t('pages.move.quotes.labour')}
                inverted
              >
                { move.estimatedMovingLabourTime }h
              </Value>
            </ItemsRow>
          </div>

          <div className="hidden h-[75px] w-px bg-neutral-300 xl:flex" />

          <div className="my-2 xl:mt-0">
            <Button
              startIcon={<EditIcon />}
              variant={ButtonVariant.Outlined}
              color={ButtonColor.Secondary}
              size={ButtonSize.Small}
              onClick={() => { navigate(Route.EditMove) }}
            >
              { t('actions.edit') }
            </Button>
          </div>

        </div>

      </div>
    </div>
  )
}

export default MoveSummaryBanner
