import Language from '../../../app/Language.enum'
import Calendly from '../../../components/Calendly'
import Spinner from '../../../components/Spinner'
import { useConfig } from '../../../modules/config'
import { useLanguage } from '../../../modules/i18n'
import { type Move, useUpdateMoveAction } from '../../../modules/moves'

type MoveCalendlyProps = {
  move?: Move
  onEventScheduled?: () => void
}

const MoveCalendly: React.FC<MoveCalendlyProps> = ({
  move,
  onEventScheduled,
}) => {
  const [updateMove, { loading }] = useUpdateMoveAction()
  const language = useLanguage() ?? Language.En
  const calendlyUrl = useConfig(`calendly.requestMovingQuote.url.${language}`)

  const handleEventScheduled = async (uri: string) => {
    if (!move) {
      return
    }
    await updateMove({
      calendlyScheduledEventUri: uri,
    })
    onEventScheduled?.()
  }

  return (
    <div className="mt-4">
      { loading && <Spinner floating /> }
      <Calendly
        calendlyUrl={calendlyUrl}
        user={move?.user}
        moveId={move?.id}
        onEventScheduled={handleEventScheduled}
        hideLandingPageDetails
      />
    </div>
  )
}

export default MoveCalendly
