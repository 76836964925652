/**
 * get a random color based on customer id
 */
export const getCustomerColor = (ratingId: string, saturation = 100, lightness = 30) => {
  let hash = 0
  for (let i = 0; i < ratingId.length; i++) {
    hash = ratingId.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`
}

/**
 * get customer name abbreviation
 * ex: John D. = JD
 */
export const getCustomerNameAbbreviation = (customerName: string) => {
  return [
    customerName[0],
    customerName.substring(customerName.length - 2, customerName.length - 1),
  ].join('').toLocaleUpperCase()
}
