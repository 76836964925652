import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

import { type PublicCompanyBranch } from '../../modules/companies'
import { mergeClassName } from '../../utils/mergeClassName'
import { Modal, ModalDescription, ModalFooter } from '../Modal'
import CompanyBranchRatings from './CompanyBranchRatings'

type CompanyBranchRatingsModalProps = {
  open: boolean
  onClose: () => void
  companyBranch: PublicCompanyBranch
  small?: boolean
}

const CompanyBranchRatingsModal: React.FC<CompanyBranchRatingsModalProps> = ({
  open,
  onClose,
  companyBranch,
  small = false,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={mergeClassName(
        !small && 'xl:max-w-[1200px]',
      )}
    >
      <ModalDescription className="max-h-[calc(100dvh-150px)] overflow-auto">

        <CompanyBranchRatings
          companyBranch={companyBranch}
          small={small}
        />

      </ModalDescription>

      <ModalFooter className={mergeClassName(
        !small && 'lg:hidden',
      )}
      >
        <div className="-mb-2 mt-1 w-full">
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onClose}
          >
            { t('actions.close') }
          </Button>
        </div>
      </ModalFooter>

    </Modal>
  )
}

export default CompanyBranchRatingsModal
