import { useTranslation, Trans } from 'react-i18next'
import { PageContent } from '../../../components/Container'
import FullWidthPage from '../../../components/layout/FullWidthPage'
import { Heading } from '../../../components/typography'
import { useCurrentMove } from '../../../modules/moves'
import Link, { LinkVariant } from '../../../components/Link'

const PhoneLink = () => <Link to="tel:18442586683" variant={LinkVariant.Primary} target="_blank">1 (844) 258-MOVE (6683)</Link>

const EmailLink = () => <Link to="mailto:people@movingwaldo.com" variant={LinkVariant.Primary} target="_blank">people@movingwaldo.com</Link>

const MoveCalendlyBookedPage: React.FC = () => {
  const { t } = useTranslation()
  const move = useCurrentMove()

  return (
    <FullWidthPage>
      <PageContent>

        <div className="pt-4 text-center">

          <Heading variant="h1">
            { t('pages.move.noQuotes.calendlyBooked.title', {
              firstName: move.user.firstName,
            }) }
          </Heading>

          <div className="my-8 font-sans text-lg">
            { t('pages.move.noQuotes.calendlyBooked.description') }
          </div>

          <div className="my-8 font-sans text-lg">
            <Trans i18nKey="pages.move.noQuotes.calendlyBooked.coordinates">
              <PhoneLink />
              <EmailLink />
            </Trans>
          </div>

        </div>

      </PageContent>
    </FullWidthPage>
  )
}

export default MoveCalendlyBookedPage
