import ReactGa4 from 'react-ga4'
import ReactFacebookPixel from 'react-facebook-pixel'

import { useConfig } from '../../config'
import {
  ANALYTICS_EVENTS,
  FACEBOOK_EVENTS,
} from './analytics.types'

/**
 * push data to Plausible (analytics)
 */
export const usePlausible = () => {
  const debug = useConfig<boolean>('analytics.debug') ?? false

  const pushPlausibleEvent = (eventName: ANALYTICS_EVENTS, params?: any) => {
    try {
      const event = ANALYTICS_EVENTS[eventName]
      const plausible = (window as any).plausible
      if (!plausible) {
        return
      }
      const { price, currency, pagePath, ...props } = params
      const revenue = price ? { amount: price, currency } : {}

      const plausibleEvent = eventName === ANALYTICS_EVENTS.page_view ? `bookingApp_visit-${String(pagePath)}` : event

      plausible(plausibleEvent, { props, revenue })

      if (debug) {
        console.log(`[TRACKING Plausible] ${plausibleEvent}`, { props, revenue })
      }
    } catch (error) {
      /* istanbul ignore next */
      console.error(error)
    }
  }

  return { pushPlausibleEvent }
}

/**
 * push data to Google Analytics
 */
export const useGoogleAnalytics = () => {
  const debug = useConfig<boolean>('analytics.debug') ?? false

  const pushGoogleEvent = (eventName: ANALYTICS_EVENTS, params?: any) => {
    try {
      const event = ANALYTICS_EVENTS[eventName]
      ReactGa4.event(event, params)

      if (debug) {
        console.log(`[TRACKING Google] ${event}`, params)
      }
    } catch (error) {
      /* istanbul ignore next */
      console.error(error)
    }
  }

  return { pushGoogleEvent }
}

/**
 * push data to Facebook (ads compaign tracking)
 */
export const useFacebook = () => {
  const debug = useConfig<boolean>('analytics.debug') ?? false

  const pushFacebookEvent = (eventName: FACEBOOK_EVENTS, params?: any) => {
    try {
      const event = FACEBOOK_EVENTS[eventName]
      ReactFacebookPixel.trackCustom(event, params)

      if (debug) {
        console.log(`[TRACKING Facebook] ${event}`, params)
      }
    } catch (error) {
      /* istanbul ignore next */
      console.error(error)
    }
  }

  return { pushFacebookEvent }
}
