import { useState } from 'react'

import { type PublicCompanyBranch } from '../../modules/companies'
import { CompanyBranchRatingsModal } from '../CompanyBranchRatings'
import Rating, { type RatingProps } from './Rating'
import { Source } from './Rating.types'

type MovingWaldoRatingProps = {
  companyBranch: PublicCompanyBranch
  withReviewsModal?: boolean
} & Omit<RatingProps, 'rating' | 'nbRatings' | 'source'>

const MovingWaldoRating: React.FC<MovingWaldoRatingProps> = ({
  companyBranch,
  withReviewsModal = false,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const rating = companyBranch.ratings?.movingWaldo?.rating
  const nbRatings = companyBranch.ratings?.movingWaldo?.nbRatings

  if (!rating || !nbRatings) {
    return null
  }

  const handleOpenReviews = (event: React.MouseEvent) => {
    if (!withReviewsModal) {
      return
    }
    event.preventDefault()
    event.stopPropagation()
    setOpen(true)
  }

  return (
    <>
      <CompanyBranchRatingsModal
        open={open}
        onClose={() => { setOpen(false) }}
        companyBranch={companyBranch}
      />
      <Rating
        rating={rating}
        nbRatings={nbRatings}
        source={Source.MovingWaldo}
        onClick={handleOpenReviews}
        {...props}
      />
    </>
  )
}

export default MovingWaldoRating
