import { useState, useEffect } from 'react'
import { type DocumentNode } from 'graphql'
import {
  type OperationVariables,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'

import {
  type MergeData,
  type QueryOptions,
  type QueryResult,
  type LazyQueryOptions,
  type LazyQueryResult,
  type PaginatedQueryResultData,
  type PaginatedVariables,
  type MutationOptions,
  type MutationResult,
  type InfiniteScrollVariables,
} from './graphQl.type'
import { formatData, getFetchPolicy, isLoading, isReloading } from './graphQl.utils'

/**
 * wrapper around Apollo useQuery
 */
export const useData = <TData, TVariables extends OperationVariables = any>(
  query: DocumentNode,
  options?: QueryOptions<TData, TVariables>,
): QueryResult<TData> => {
  const {
    variables,
    reload = false,
    skip = false,
    usePreviousData = false,
    adapter,
  } = options ?? {}
  const {
    data,
    previousData,
    called,
    loading,
    networkStatus,
    error,
    refetch,
  } = useQuery(
    query,
    {
      skip,
      fetchPolicy: getFetchPolicy({ reload }),
      variables,
    },
  )

  const dataToUse = usePreviousData ? (data ?? previousData) : data

  return {
    data: dataToUse ? formatData(dataToUse, adapter) : undefined,
    called,
    loading: loading && isLoading(networkStatus),
    reloading: loading && isReloading(networkStatus),
    error,
    refetch: async () => {
      const { data } = await refetch()
      return formatData<TData>(data, adapter)
    },
  }
}

/**
 * wrapper around Apollo useLazyQuery
 */
export const useLazyData = <TData, TVariables extends OperationVariables = any>(
  query: DocumentNode,
  options?: LazyQueryOptions<TData>,
): LazyQueryResult<TData, TVariables> => {
  const {
    reload = false,
    usePreviousData = false,
    adapter,
  } = options ?? {}

  const [fetch, {
    data,
    previousData,
    called,
    loading,
    networkStatus,
    error,
    fetchMore,
  }] = useLazyQuery(
    query,
    {
      fetchPolicy: getFetchPolicy({ reload }),
    },
  )

  const dataToUse = usePreviousData ? (data ?? previousData) : data

  return {
    data: dataToUse ? formatData(dataToUse, adapter) : undefined,
    called,
    loading: loading && isLoading(networkStatus),
    error,
    fetch: async (variables?: TVariables) => {
      const { data, error } = await fetch({ variables })
      if (error) {
        throw error
      }
      return formatData<TData>(data, adapter)
    },
    fetchMore: async (variables?: TVariables, mergeData?: MergeData<TData>) => {
      const updateQuery = mergeData
        ? (existingData: any, { fetchMoreResult: newData }: any) => {
            const key = Object.keys(existingData)[0]
            return {
              [key]: mergeData(
                existingData[key],
                newData[key],
              ),
            }
          }
        : undefined
      const { data, error } = await fetchMore({ variables, updateQuery })
      if (error) {
        throw error
      }
      return formatData<TData>(data, adapter)
    },
  }
}

/**
 * helper to fetch paginated data
 */
export const usePaginatedData = <TData, TVariables extends PaginatedVariables = any>(
  query: DocumentNode,
) => {
  return useLazyData<PaginatedQueryResultData<TData>, TVariables>(
    query,
    {
      reload: true,
      usePreviousData: true,
    },
  )
}

/**
 * helper to fetch data using infinite scroll
 * really similar to paginated data but the page is handled internally
 * and all the collected data is returned
 * The data collected is reset as soon as the query change (sorting, search, variables, etc)
 * Unlike the paginated query, this helper is not lazy
 *  - initial query is triggered automatically
 *  - data are reset and first batch is loaded automatically when parameters changes
 * Up to bottom scroll direction is expected
 */
export const useInfiniteScrollData = <TData, TVariables extends InfiniteScrollVariables = any>(
  query: DocumentNode,
  variables?: TVariables,
) => {
  const [page, setPage] = useState(0)
  // to avoid a 5 years old bug : https://github.com/apollographql/apollo-client/issues/7243
  const [loadingMore, setLoadingMore] = useState(false)
  const [serializedVariabled, setSerializedVariabled] = useState<string>()

  const { fetch, fetchMore, ...data } = useLazyData<PaginatedQueryResultData<TData>, TVariables>(
    query,
    {
      reload: true,
    },
  )

  const hasMore = data?.data?.hasMore ?? true

  /**
   * load or reload data
   */
  const getData = async () => {
    if (loadingMore) {
      return
    }
    const nextPage = page + 1

    if (nextPage > 1 && !hasMore) {
      console.error('End of results reached')
      return
    }

    const variableWithPagination = {
      ...variables,
      query: {
        ...variables?.query,
        page: nextPage,
      },
    } as unknown as TVariables

    setPage(nextPage)

    setLoadingMore(true)

    if (nextPage === 1) {
      const response = await fetch(variableWithPagination)
      setLoadingMore(false)

      return response
    }

    const response = await fetchMore(variableWithPagination, (
      previousData: PaginatedQueryResultData<TData>,
      newData: PaginatedQueryResultData<TData>,
    ) => {
    /* ignore data received in multiple batches */

      newData.data = newData.data.filter(newEntry => {
        return !previousData.data.find((existingEntry) => {
          return (existingEntry as any)?.id === (newEntry as any)?.id
        })
      })

      return {
        ...previousData,
        count: previousData.count,
        hasMore: newData.hasMore,
        data: [
          ...previousData.data,
          ...newData.data,
        ],
      }
    })

    setLoadingMore(false)
    return response
  }

  /**
   * reset page variables are set (or change)
   */
  useEffect(() => {
    const incomingVariables = JSON.stringify(variables)
    if (incomingVariables === serializedVariabled) {
      return
    }

    setSerializedVariabled(incomingVariables)
    setPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables])

  /**
   * always load the first batch
   */
  useEffect(() => {
    if (page === 0 && !loadingMore) {
      getData().catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return {
    fetchMore: getData,
    ...data,
    loading: data.loading || loadingMore,
  }
}

/**
 * wrapper around Apollo useMutation
 */
export const useAction = <TData = any, TVariables extends OperationVariables = any>(
  mutation: DocumentNode,
  options?: MutationOptions<TData>,
): MutationResult<TData, TVariables> => {
  const {
    adapter,
    ignoreResults = false,
  } = options ?? {}

  const [mutate, data] = useMutation(mutation)

  const execute = async (
    variables?: TVariables,
  ): Promise<TData> => {
    const { data } = await mutate({
      variables,
      ignoreResults,
      fetchPolicy: ignoreResults ? 'no-cache' : undefined,
    })
    return formatData<TData>(data, adapter)
  }
  return [execute, {
    ...data,
    data: formatData<TData>(data, adapter),
  }]
}

type Formatter = (...args: any) => any

/**
 * a helper when the action payload is different from the mutation payload
 */
export const useActionWithPayload = <TData, TFormatter extends Formatter, TVariables extends Parameters<TFormatter>>(
  action: ReturnType<typeof useAction<TData>>,
  formatter: TFormatter,
) => {
  const [mutate, data] = action

  const execute = async (...variables: TVariables) => {
    return await mutate(formatter(...variables))
  }

  return [execute, data] as [typeof execute, typeof data]
}
