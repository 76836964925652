import { createContext, useContext } from 'react'

import { type ExperimentContextType } from './Experiment.types'

const ExperimentContext = createContext<ExperimentContextType | null>(null)

type ExperimentContextProviderProps = ExperimentContextType & {
  children?: React.ReactNode
}

export const ExperimentProvider: React.FC<ExperimentContextProviderProps> = ({
  children,
  ...context
}) => {
  return (
    <ExperimentContext.Provider value={context}>
      { children }
    </ExperimentContext.Provider>
  )
}

export const useExperimentContext = () => {
  const context = useContext(ExperimentContext)
  if (!context) {
    throw new Error('Missing experiment context. Did you forget to use <Experiment/> ?')
  }
  return context
}
