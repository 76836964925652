import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { RatingSorting } from '../../../modules/companyRatings'
import { mergeClassName } from '../../../utils/mergeClassName'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '../../DropDownMenu'

type HeaderProps = {
  count: number
  sorting: RatingSorting
  setSorting: (sorting: RatingSorting) => void
  small?: boolean
}

const Header: React.FC<HeaderProps> = ({
  count,
  sorting,
  setSorting,
  small = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className="sticky top-[-16px] bg-white">
      <div className="mb-4 flex flex-wrap items-center justify-between gap-x-2 border-b pb-4">
        <div className="text-lg text-neutral-700">
          { !!count && (
            <>
              { t('components.companyBranchRatings.header.nbReviews', { count }) }
            </>
          ) }
        </div>

        <div className={mergeClassName(
          'mr-6',
          !small && 'xl:mr-0',
        )}
        >
          <DropdownMenu>
            <DropdownMenuTrigger asChild>

              <Button
                variant="outlined"
                color="secondary"
                endIcon={<KeyboardArrowDownIcon />}
                className="!pl-4 !normal-case"
                disableFocusRipple
                size="small"
              >
                { t(`components.companyBranchRatings.header.sorting.${sorting}`) }
              </Button>

            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" sideOffset={8}>

              { Object.values(RatingSorting)
                .filter((option) => option !== sorting)
                .map((option) => (
                  <DropdownMenuItem
                    key={option}
                    onClick={() => { setSorting(option) }}
                  >
                    { t(`components.companyBranchRatings.header.sorting.${option}`) }
                  </DropdownMenuItem>
                )) }

            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  )
}

export default Header
