import {
  type OperationVariables,
} from '@apollo/client'

export type DataAdapter<TData> = (rawData: unknown) => TData

export type PayloadFormatter<TVariables> = (rawData: TVariables) => unknown

export type QueryOptions<TData, TVariables = any> = {
  variables?: TVariables
  reload?: boolean
  skip?: boolean
  usePreviousData?: boolean
  adapter?: DataAdapter<TData>
}

export type MergeData<TData> = (data: TData, newData: TData) => TData

export type LazyQueryOptions<TData> = Omit<QueryOptions<TData>, 'variables' | 'skip'>

export type MutationOptions<TData> = {
  adapter?: DataAdapter<TData>
  ignoreResults?: boolean
}

/* options exposed to consummers when fetching data */
export type DataOptions = Pick<QueryOptions<unknown>, 'reload' | 'skip'>
export type LazyDataOptions = Pick<LazyQueryOptions<unknown>, 'reload'>

/* options exposed to consummers when executing actions */
export type ActionOptions = Pick<MutationOptions<unknown>, 'ignoreResults'>

export type QueryResult<TData> = {
  data: TData | undefined
  called: boolean
  loading: boolean
  reloading: boolean
  refetch: () => Promise<TData>
  error?: Error
}

export type LazyQueryResult<TData, TVariables extends OperationVariables = any> = Omit<QueryResult<TData>, 'refetch' | 'reloading'> & {
  fetch: (variables?: TVariables) => Promise<TData>
  fetchMore: (variables?: TVariables, mergeData?: MergeData<TData>) => Promise<TData>
}

export type MutationResult<TData = any, TVariables extends OperationVariables = any> = [
  (variables: TVariables) => Promise<TData>,
  Omit<QueryResult<TData | undefined>, 'refetch' | 'reloading'>
]

export type PaginatedQueryResultData<T> = {
  count: number
  hasMore: boolean
  data: T[]
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PaginatedQuery = {
  nbResultsPerPage?: number
  page?: number
  sortField?: string
  sortDirection?: SortDirection
  search?: string
  excludeDeleted?: boolean
}

export type InfiniteScrollQuery = Omit<PaginatedQuery, 'page'>

export type PaginatedVariables = {
  query?: PaginatedQuery
}

export type InfiniteScrollVariables = {
  query?: InfiniteScrollQuery
}
