import { useExperimentGroup } from '../../abTesting'
import { useFacebook, useGoogleAnalytics, usePlausible } from './analytics.source.hooks'
import {
  ANALYTICS_EVENTS,
  FACEBOOK_EVENTS,
  type NewMoveParams,
  type QuotesAcceptedEventParams,
  type QuotesSelectedEventParams,
} from './analytics.types'

export const useAnalytics = () => {
  const { pushPlausibleEvent } = usePlausible()
  const { pushGoogleEvent } = useGoogleAnalytics()
  const { pushFacebookEvent } = useFacebook()
  const abTesting = useExperimentGroup()

  /**
   * push the same event to both Plausible and GA
   * we inject the current ab testing group in the event params
   */
  const pushAnalyticEvent = (eventName: ANALYTICS_EVENTS, params: any = {}) => {
    if (abTesting) {
      params.abTesting = abTesting
    }
    pushPlausibleEvent(eventName, params)
    pushGoogleEvent(eventName, params)
  }

  /**
   * custom page view are usefull in interactive flow like the quote process
   */
  const pushCustomPageView = (url: string) => {
    pushAnalyticEvent(ANALYTICS_EVENTS.page_view, { pagePath: url })
  }

  /**
   * indicate that the user start the quote process
   */
  const pushStartQuotesRequestEvent = () => {
    pushAnalyticEvent(ANALYTICS_EVENTS.bookingApp_startQuotesRequest)
  }

  /**
   * push the event when the user request quotes
   * sent after the 3rd step when the user first see his 3 best prices
   */
  const pushQuotesRequestedEvent = ({
    nbQuotesReceived,
    cheapestPriceReceived,
    ...metrics
  }: NewMoveParams) => {
    pushAnalyticEvent(ANALYTICS_EVENTS.bookingApp_quotesRequested, {
      agentRequired: nbQuotesReceived === 0 ? 'yes' : 'no',
      nbQuotesReceived,
      cheapestPriceReceived: cheapestPriceReceived?.price,
      currency: cheapestPriceReceived?.currency,
      ...metrics,
    })
  }

  /**
   * push the event when the user create a move
   * sent when the user fill the form with his name and email
   */
  const pushMoveCreatedEvent = ({
    nbQuotesReceived,
    cheapestPriceReceived,
    ...metrics
  }: NewMoveParams) => {
    pushAnalyticEvent(ANALYTICS_EVENTS.bookingApp_moveCreated, {
      agentRequired: nbQuotesReceived === 0 ? 'yes' : 'no',
      nbQuotesReceived,
      cheapestPriceReceived: cheapestPriceReceived?.price,
      currency: cheapestPriceReceived?.currency,
      ...metrics,
    })
    pushFacebookEvent(FACEBOOK_EVENTS.CompleteRegistration)
  }

  /**
   * push the event when the user book an agent
   */
  const pushAgentBookedEvent = () => {
    pushAnalyticEvent(ANALYTICS_EVENTS.bookingApp_agentBooked)
    pushFacebookEvent(FACEBOOK_EVENTS.Schedule)
  }

  /**
   * push the event when the user select a quote
   */
  const pushQuoteSelectedEvent = ({
    price,
    ...metrics
  }: QuotesSelectedEventParams) => {
    pushAnalyticEvent(ANALYTICS_EVENTS.bookingApp_quoteSelected, {
      price: price.price,
      currency: price.currency,
      ...metrics,
    })
    pushFacebookEvent(FACEBOOK_EVENTS.InitiateCheckout)
  }

  /**
   * push the event when the user unselect a quote
   */
  const pushQuoteUnselectedEvent = () => {
    pushAnalyticEvent(ANALYTICS_EVENTS.bookingApp_quoteUnselected)
  }

  /**
   * push the event when the user accept a quote on the checkout page
   */
  const pushQuoteAcceptedEvent = ({
    price,
    ...metrics
  }: QuotesAcceptedEventParams) => {
    pushAnalyticEvent(ANALYTICS_EVENTS.bookingApp_quoteAccepted, {
      price: price.price,
      currency: price.currency,
      ...metrics,
    })
    pushFacebookEvent(FACEBOOK_EVENTS.Purchase, {
      value: price.price,
      currency: price.currency,
    })
  }

  /**
   * push the event when the user add a promo code
   */
  const pushPromoCodeAddedEvent = (code: string) => {
    pushAnalyticEvent(ANALYTICS_EVENTS.bookingApp_PromoCodeAdded, {
      code,
    })
  }

  /**
   * push the event when the user remove a promo code
   */
  const pushPromoCodeRemovedEvent = (code: string) => {
    pushAnalyticEvent(ANALYTICS_EVENTS.bookingApp_PromoCodeRemoved, {
      code,
    })
  }

  return {
    pushCustomPageView,
    pushStartQuotesRequestEvent,
    pushQuotesRequestedEvent,
    pushMoveCreatedEvent,
    pushAgentBookedEvent,
    pushQuoteSelectedEvent,
    pushQuoteUnselectedEvent,
    pushQuoteAcceptedEvent,
    pushPromoCodeAddedEvent,
    pushPromoCodeRemovedEvent,
  }
}
