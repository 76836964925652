import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { type UpdateMovePayload, useCurrentMove, useSelectedQuote } from '../../../../modules/moves'
import Form, { PlaceField, RadioGroup } from '../../../Form'
import AddressFields from '../../../Form/fields/PlaceField/AddressFields'
import SubmitBar from '../SubmitBar'
import useValidationSchema from './validationSchema'
import { type AddressesFormValues } from './AddressesForm.type'
import { formatFormOutput, moveToFormData } from './formDataBuilder'
import { Label, Row, Cell, Field } from '../../../data'
import NbStairsControl from '../../quotesRequest/AddressesForm/NbStairsControl'
import { mergeClassName } from '../../../../utils/mergeClassName'

type AddressesFormProps = {
  onSubmit: (data: UpdateMovePayload) => Promise<void> | void
  onCancel?: () => void
  wide?: boolean
}

const AddressesForm: React.FC<AddressesFormProps> = ({
  onCancel,
  onSubmit,
  wide = false,
}) => {
  const { t } = useTranslation()
  const move = useCurrentMove()
  const selectedQuote = useSelectedQuote()

  const specificAddresses = !!selectedQuote

  const schema = useValidationSchema({ specificAddresses })
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: moveToFormData(move, { specificAddresses }),
  })

  const handleSubmit = async (values: AddressesFormValues) => { await onSubmit(formatFormOutput(values)) }

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <Row className={mergeClassName(
        !wide && 'lg:flex-col',
      )}
      >

        { ['origin', 'destination'].map(address => {
          const isOrigin = address === 'origin'
          const isDestination = address === 'destination'

          return (
            <Cell key={address}>
              <div className={mergeClassName(
                'h-full rounded-md border p-4 shadow lg:border-none',
                isOrigin && wide && 'lg:mr-2',
                isDestination && wide && 'lg:ml-2',
              )}
              >
                <Label>
                  { isOrigin && t('components.move.addresses.movingFrom') }
                  { isDestination && t('components.move.addresses.movingTo') }
                </Label>

                <Field>
                  { specificAddresses
                    ? (
                      <AddressFields name={`${address}.address`} />
                      )
                    : (
                      <PlaceField name={`${address}.address`} />
                      ) }
                </Field>

                <Row className={mergeClassName(
                  'lg:my-0',
                  wide && 'lg:flex-col lg:gap-y-4',
                )}
                >
                  <Cell>

                    <Label>
                      { t('components.move.addresses.nbStairs') }
                    </Label>
                    <NbStairsControl name={`${address}.nbStairs`} />

                  </Cell>

                  { specificAddresses && (
                    <Cell>
                      <Label>
                        { t('components.move.addresses.elevator') }
                      </Label>
                      <div className="mt-2">
                        <RadioGroup
                          name={`${address}.elevatorReservationRequired`}
                          options={[
                            {
                              value: '0',
                              label: (
                                <span className="inline-block py-1">
                                  { t('components.move.addresses.elevatorNotNeeded') }
                                </span>
                              ),
                            },
                            {
                              value: '1',
                              label: (
                                <span className="inline-block py-1">
                                  { t('components.move.addresses.elevatorNeeded') }
                                </span>
                              ),
                            },
                          ]}
                        />
                      </div>

                    </Cell>
                  ) }
                </Row>
              </div>
            </Cell>
          )
        }) }

      </Row>

      <SubmitBar onCancel={onCancel} />
    </Form>
  )
}

export default AddressesForm
