import { useState, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import PhoneInput, { type CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import './style.css'
import { useCountries, useCountry } from '../../../../modules/localization'
import { mergeClassName } from '../../../../utils/mergeClassName'
import get from '../../../../utils/get'
import { InputSize } from '../../controls/Input'
import { Input } from '../../'

type PhoneNumberFieldProps = {
  name: string
  size?: InputSize
  fullWidth?: boolean
  label?: string
  placeholder?: string
  required?: boolean
  className?: string
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  showExtension?: boolean
  showFlag?: boolean
  center?: boolean
}

const tollFreeRegex = /^18[0-9]{2}$/
const formattedTollFreeRegex = /^8[0-9]{2}/

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  name,
  size = InputSize.Medium,
  label,
  placeholder = '(000) 000-0000',
  required,
  className,
  inputProps,
  showExtension = true,
  showFlag = false,
  center = false,
}) => {
  const numberFieldName = `${name}.number`
  const extensionFieldName = `${name}.extension`
  const [inputValue, setInputValue] = useState('')
  const { setValue: setFormValue, formState: { errors, touchedFields } } = useFormContext()
  const formValue = useWatch({ name: numberFieldName })
  const controlError = get(errors, name)?.number
  const countryCodes = Object.keys(useCountries()).map(code => code.toLowerCase())
  const currentCountry = useCountry().toLowerCase()

  const hasError = !!controlError?.message
  const touched = touchedFields[name]
  const shouldValidate = touched || hasError

  let formattedLabel = ''
  if (label) {
    formattedLabel = required ? `${label} *` : label
  }

  /**
   * format number and register it in the form
   */
  const handleChange = (newValue: string, countryData: CountryData) => {
    const newInputValue = tollFreeRegex.test(newValue)
      ? newValue.substring(1)
      : newValue

    let newFormValue = newValue
    if (formattedTollFreeRegex.test(newInputValue)) {
      newFormValue = `1${newInputValue}`
    } else if (newInputValue.length >= 4) {
      newFormValue = `+${countryData.dialCode}${newInputValue}`
    }

    setInputValue(newInputValue)
    setFormValue(numberFieldName, newFormValue, { shouldValidate })
  }

  /**
   * handle form value update xternally
   */
  useEffect(() => {
    if (!formValue) {
      return
    }
    let formNumberValue = formValue
    if (formValue?.[0] === '+') {
      formNumberValue = formValue.substring(2)
    } else if (tollFreeRegex.test(formNumberValue?.substring(0, 4))) {
      formNumberValue = formValue.substring(1)
    }
    if (formNumberValue !== inputValue) {
      setInputValue(formNumberValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue])

  return (
    <div className="flex w-full flex-col">
      <div className={mergeClassName(
        'flex w-full items-end gap-2',
        (!showFlag || inputValue.length < 3) && 'hide-flag',
        'input-outlined',
      )}
      >
        <PhoneInput
          value={inputValue}
          onChange={handleChange}
          onlyCountries={countryCodes}
          country={currentCountry}
          disableDropdown
          disableCountryCode
          placeholder={formattedLabel || placeholder}
          specialLabel={inputValue ? formattedLabel : ''}
          inputClass={mergeClassName(
            'focus:!border-black focus:!shadow-[0_0_0_1px_#000] !w-full placeholder-[#666]',
            size === 'small' && '!py-[7px]',
            size === 'medium' && '!py-[15px]',
            center && 'text-center',
            className,
            hasError && '!text-[#d32f2f] !border-[#d32f2f] focus:!border-[#d32f2f] !placeholder-[#d32f2f]/50',
          )}
          inputProps={inputProps}
        />

        { showExtension && (
          <Input
            name={extensionFieldName}
            size={size}
            placeholder="Ext."
            className="!w-[110px]"
            maxLength={8}
          />
        ) }
      </div>
      { typeof controlError?.message === 'string' && (
        <div className="ml-3 mt-1 text-xs text-[#d32f2f]">
          { controlError?.message }
        </div>
      ) }
    </div>
  )
}

export default PhoneNumberField
