import FilledStar from '@mui/icons-material/Star'
import UnfilledStar from '@mui/icons-material/StarBorder'

type StarsRatingProps = {
  rating: number
  max?: number
}

const StarsRating: React.FC<StarsRatingProps> = ({
  rating,
  max = 5,
}) => {
  return (
    <div className="inline-flex items-center gap-x-px text-neutral-500 child:!text-[16px]">
      { Array.from({ length: max }).map((_, index) => {
        const key = index + 1
        const filled = !!(rating && rating >= key)
        const Icon = filled ? FilledStar : UnfilledStar

        return (
          <Icon
            key={key}
          />
        )
      }) }
    </div>
  )
}

export default StarsRating
