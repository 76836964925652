import { useTranslation } from 'react-i18next'
import StarIcon from '@mui/icons-material/Star'
import PunctualityIcon from '@mui/icons-material/AccessTime'
import ProfessionalismIcon from '@mui/icons-material/MoodOutlined'
import CommunicationIcon from '@mui/icons-material/WifiCalling3Outlined'

import { type PublicCompanyBranch } from '../../../modules/companies'
import { mergeClassName } from '../../../utils/mergeClassName'

type SidebarProps = {
  companyBranch: PublicCompanyBranch
  small?: boolean
}

const Sidebar: React.FC<SidebarProps> = ({
  companyBranch,
  small = false,
}) => {
  const { t } = useTranslation()

  if (!companyBranch.ratings?.movingWaldo?.rating) {
    return null
  }

  return (
    <div className={mergeClassName(
      'flex justify-center mb-4',
      !small && 'xl:justify-normal xl:mb-0',
    )}
    >
      <div className={mergeClassName(
        'my-4 w-full shrink-0 md:w-[350px]',
        !small && 'xl:my-8 xl:-mt-16',
      )}
      >
        <div className="text-center">
          <div className="flex items-center justify-center gap-x-3 font-body2">
            <StarIcon className="-ml-16 mr-2 !text-[48px] text-yellow-500" />
            <span className="text-7xl text-neutral-700">
              { companyBranch.ratings.movingWaldo.rating.toFixed(1) }
            </span>
            <span className="text-2xl text-neutral-500">
              / 5
            </span>
          </div>

          <div className={mergeClassName(
            'py-6 text-base leading-tight',
            !small && 'xl:py-8 xl:text-sm xl:leading-normal',
          )}
          >
            { t('components.companyBranchRatings.sidebar.subtitle') }
          </div>
        </div>

        <ul className={mergeClassName(
          'text-base text-neutral-700 child:flex child:items-center child:justify-between child:border-b child:py-4 last:child:border-0 md:text-lg',
          !small && 'xl:child:py-6',
        )}
        >
          { companyBranch.ratings?.movingWaldo?.professionalism && (
            <li>
              <div className="flex items-center gap-x-4">
                <ProfessionalismIcon className="text-gray-400/80" />
                { t('components.companyBranchRatings.sidebar.subCategories.professionalism') }
              </div>
              <div className="flex gap-x-2">
                <span className="font-body2 text-xl font-bold">
                  { companyBranch.ratings.movingWaldo.professionalism.toFixed(1) }
                </span>
                <span className="font-body2 text-sm text-neutral-400">/ 5</span>
              </div>
            </li>
          ) }

          { companyBranch.ratings?.movingWaldo?.punctuality && (
            <li>
              <div className="flex items-center gap-x-4">
                <PunctualityIcon className="text-gray-400/80" />
                { t('components.companyBranchRatings.sidebar.subCategories.punctuality') }
              </div>
              <div className="flex gap-x-2">
                <span className="font-body2 text-xl font-bold">
                  { companyBranch.ratings.movingWaldo.punctuality.toFixed(1) }
                </span>
                <span className="font-body2 text-sm text-neutral-400">/ 5</span>
              </div>
            </li>
          ) }

          { companyBranch.ratings?.movingWaldo?.communication && (
            <li>
              <div className="flex items-center gap-x-4">
                <CommunicationIcon className="text-gray-400/80" />
                { t('components.companyBranchRatings.sidebar.subCategories.communication') }
              </div>
              <div className="flex gap-x-2">
                <span className="font-body2 text-xl font-bold">
                  { companyBranch.ratings.movingWaldo.communication.toFixed(1) }
                </span>
                <span className="font-body2 text-sm text-neutral-400">/ 5</span>
              </div>
            </li>
          ) }
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
