/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next'

import { useSelectQuoteAction, useSelectedQuote } from '../../../modules/moves'
import { QuoteIdentification } from '../../../components/quotes/Quotes/Quote'
import Button, { ButtonColor, ButtonSize } from '../../../components/Button'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import Spinner from '../../../components/Spinner'
import { errorNotification } from '../../../components/ToastNotifications'

const SelectedQuote: React.FC = () => {
  const { t } = useTranslation()
  const selectedQuote = useSelectedQuote()
  const [selectQuote, { loading: unselecting }] = useSelectQuoteAction()
  const formatError = useErrorFormatter()
  const hasCustomQuote = !!selectedQuote?.customized

  if (!selectedQuote) {
    return null
  }

  const clearSelection = async () => {
    try {
      await selectQuote(null)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <>
      { unselecting && (
        <Spinner floating backdrop />
      ) }

      <div className="flex min-h-[135px] grow flex-col items-center justify-between gap-x-4 rounded-md border bg-white py-6 shadow-xl sm:flex-row sm:pr-8 md:py-0">
        <div>
          <QuoteIdentification
            quote={selectedQuote}
            showDate={false}
            showFullTrucksDetails
            companyNameClassName="block"
          />
        </div>

        { !hasCustomQuote && (
          <div className="pt-4 sm:pt-0">
            <Button
              onClick={clearSelection}
              size={ButtonSize.Small}
              color={ButtonColor.Secondary}
            >
              { t('actions.change') }
            </Button>
          </div>
        ) }
      </div>
    </>
  )
}

export default SelectedQuote
