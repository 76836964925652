import { useState } from 'react'

import { RatingSorting, useCompanyBranchRatings } from '../../modules/companyRatings'
import { type PublicCompanyBranch } from '../../modules/companies'
import { mergeClassName } from '../../utils/mergeClassName'
import InfiniteScroll from '../InfiniteScroll'
import Header, { Title } from './Header'
import Sidebar from './Sidebar'
import Rating from './Rating'
import EndMessage from './EndMessage'

type CompanyBranchRatingsProps = {
  companyBranch: PublicCompanyBranch
  small?: boolean
}

const CompanyBranchRatings: React.FC<CompanyBranchRatingsProps> = ({
  companyBranch,
  small = false,
}) => {
  const [sorting, setSorting] = useState(RatingSorting.MostRecent)

  const {
    fetchMoreCompanyBranchRatings,
    data: companyRatings,
    loading,
  } = useCompanyBranchRatings(companyBranch.id, sorting)

  const availablesRatings = companyRatings?.count ?? 0
  const totalRatings = Math.max(companyBranch.ratings?.movingWaldo?.nbRatings ?? 0, availablesRatings)

  return (
    <>
      <div className={mergeClassName(
        'hidden',
        !small && 'xl:block',
      )}
      >
        { /* title (large version) */ }
        <Title companyBranch={companyBranch} />
      </div>

      <div
        className={mergeClassName(
          'flex flex-col-reverse gap-x-8 pt-0',
          !small && 'xl:flex-row xl:p-8',
        )}
      >
        <div className="flex grow">
          <div className="w-full">

            <div
              className={mergeClassName(
                'hidden',
                !small && 'xl:block',
              )}
            >
              { /* header (large version) */ }
              <Header
                count={totalRatings}
                sorting={sorting}
                setSorting={setSorting}
              />
            </div>

            <InfiniteScroll
              dataLength={companyRatings?.data?.length ?? 0}
              onLoad={fetchMoreCompanyBranchRatings}
              hasMore={companyRatings?.hasMore ?? true}
              className={mergeClassName(
                'h-[calc(100dvh-175px)] overflow-y-auto pr-4 pt-4',
                !small && 'xl:h-[calc(100vh-500px)]',
              )}
              loading={loading}
              endMessage={(
                <EndMessage
                  totalRating={totalRatings}
                  availablesRatings={availablesRatings}
                  small={small}
                />
              )}
            >
              <div
                className={mergeClassName(
                  !small && 'xl:hidden',
                )}
              >
                { /* title and sidebar (small version) */ }
                <Title
                  companyBranch={companyBranch}
                  small
                />
                <Sidebar
                  companyBranch={companyBranch}
                  small={small}
                />
              </div>

              { /* header (small version) */ }
              <div className={mergeClassName(
                'sticky top-[-16px]',
                !small && 'xl:hidden',
              )}
              >
                <Header
                  count={totalRatings}
                  sorting={sorting}
                  setSorting={setSorting}
                  small={small}
                />
              </div>

              { companyRatings?.data?.map(companyRating => (
                <Rating
                  key={companyRating.id}
                  rating={companyRating}
                  companyBranch={companyBranch}
                />
              )) }
            </InfiniteScroll>
          </div>
        </div>

        <div
          className={mergeClassName(
            'hidden',
            !small && 'xl:block',
          )}
        >
          { /* sidebar (large version) */ }
          <Sidebar companyBranch={companyBranch} />
        </div>

      </div>
    </>
  )
}

export default CompanyBranchRatings
