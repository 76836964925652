import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

import config from './config'
const { newRelic: { licenseKey, accountId, applicationId } } = config
const { monitoring: { recordScreenRate, recordScreenRateError } } = config

;(() => {
  if (!applicationId) {
    console.log('New relic is not enabled in this environment')
    return
  }

  const newRelicConfig = {
    init: {
      session_replay: {
        enabled: true,
        block_selector: '',
        mask_text_selector: '*',
        sampling_rate: recordScreenRate,
        error_sampling_rate: recordScreenRateError,
        mask_all_inputs: true,
        collect_fonts: true,
        inline_images: false,
        inline_stylesheet: true,
        mask_input_options: {},
      },
      distributed_tracing: {
        enabled: true,
      },
      ajax: {
        deny_list: ['bam.nr-data.net'],
      },
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey,
      applicationID: applicationId,
      sa: 1,
    },
    loader_config: {
      accountID: accountId,
      trustKey: accountId,
      agentID: applicationId,
      licenseKey,
      applicationID: applicationId,
    },
  }

  return new BrowserAgent(newRelicConfig)
})()
