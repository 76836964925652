import { type Quote } from '../../quotes'
import { useData } from '../../graphQl'
import { validateUuid } from '../../../utils/uuid'
import * as queries from './moves.queries'
import { type Move } from './moves.models'
import { useMoveId } from './moves.hooks'

/**
 * returns move
 */
export const useMove = () => {
  const moveId = useMoveId()
  const validId = validateUuid(moveId)

  return useData<Move>(queries.getMove, {
    skip: !validId,
    variables: { moveId },
  })
}

/**
 * returns current move
 */
export const useCurrentMove = () => {
  const { data: move } = useMove()
  if (!move) {
    throw new Error('Missing move')
  }
  return move
}

/**
 * returns selected quote if any
 */
export const useSelectedQuote = (): Quote | undefined => {
  const { data: move } = useMove()
  const selectedQuote = move?.quotes?.find(({ companyBranch }) => companyBranch.id === move.selectedBranch)
  return selectedQuote
}
