import { useId } from 'react'
import InfiniteScrollComponent from 'react-infinite-scroll-component'

import { mergeClassName } from '../../utils/mergeClassName'
import Spinner from '../Spinner'

type InfiniteScrollProps = {
  dataLength: number
  hasMore: boolean
  onLoad: () => Promise<any>
  loading?: boolean
  endMessage?: React.ReactNode
  className?: string
  children?: React.ReactNode
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  dataLength,
  hasMore,
  onLoad,
  loading = false,
  endMessage,
  className,
  children,
}) => {
  const hasData = dataLength > 0
  const id = useId()

  return (
    <div
      className={mergeClassName(
        'child:h-full relative',
        className,
      )}
      id={id}
    >
      { loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner />
        </div>
      ) }
      <InfiniteScrollComponent
        dataLength={dataLength}
        next={onLoad}
        hasMore={hasMore}
        loader={(
          <div className={mergeClassName(
            'flex items-center justify-center w-full h-full',
            hasData && 'h-[50px] -mb-[50px]',
            loading && !hasData && 'hidden',
          )}
          >
            <Spinner
              className={mergeClassName(
                hasData && '!scale-75',
              )}
            />
          </div>
        )}
        className={mergeClassName(
          '!overflow-visible',
          hasMore && 'pb-[65px]',
          !hasData && '!h-full',
        )}
        scrollableTarget={id}
        endMessage={endMessage}
      >
        { children }
      </InfiniteScrollComponent>
    </div>
  )
}

export default InfiniteScroll
