import { type CompanyRating } from '../../../modules/companyRatings'
import { getCustomerColor, getCustomerNameAbbreviation } from '../CompanyBranchRatings.utils'

type CustomerAvatarProps = {
  rating: CompanyRating
}

const CustomerAvatar: React.FC<CustomerAvatarProps> = ({
  rating,
}) => {
  return (
    <div className="flex size-[60px] shrink-0 cursor-default items-center justify-center overflow-hidden rounded-full border border-neutral-300 p-1 shadow-lg">
      <div
        className="flex size-full items-center justify-center overflow-hidden rounded-full font-body2 text-xl  uppercase text-white"
        style={{
          background: getCustomerColor(rating.id),
        }}
      >
        { getCustomerNameAbbreviation(rating.customerName) }
      </div>
    </div>
  )
}

export default CustomerAvatar
