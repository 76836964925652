import { useTranslation } from 'react-i18next'

import {
  type UpdateMovePayload,
  useCurrentMove,
  useUpdateMoveAction,
} from '../../../modules/moves'
import { useNavigate } from '../../../modules/navigation'
import { formatPlace } from '../../../common/place'
import { type EditableSectionProps } from '../../EditableSections/editableSections.type'
import {
  Label,
  Value,
  BooleanValue,
  Row,
  Cell,
} from '../../data'
import AddressesForm from '../../forms/editmove/AddressesForm'
import Route from '../../../app/Route.enum'
import { mergeClassName } from '../../../utils/mergeClassName'
import {
  errorNotification,
  successNotification,
  warningNotification,
} from '../../ToastNotifications'
import { useErrorFormatter } from '../../errors/useErrorFormatter'

const AddressesSection: React.FC<EditableSectionProps> = ({
  isEditing,
  onClose,
  wide,
}) => {
  const { t } = useTranslation()
  const move = useCurrentMove()
  const [updateMove] = useUpdateMoveAction()
  const { navigate } = useNavigate()
  const formatError = useErrorFormatter()

  const onSubmit = async (data: UpdateMovePayload) => {
    try {
      const hasSelection = !!move.selectedBranch
      const updatedMove = await updateMove(data)
      onClose()
      navigate(Route.Move)
      successNotification((
        <>
          { t('components.move.addresses.success') }
          <div className="font-bold">
            { t('components.move.quoteRecalculated') }
          </div>
        </>
      ))

      if (hasSelection && !updatedMove.selectedBranch) {
        warningNotification(t('components.move.unselectedQuote'))
      }
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (isEditing) {
    return (
      <AddressesForm
        onSubmit={onSubmit}
        onCancel={onClose}
        wide={wide}
      />
    )
  }

  return (
    <Row>

      { ['origin', 'destination'].map(address => {
        const isOrigin = address === 'origin'
        const isDestination = address === 'destination'
        const data = isOrigin ? move.origin : move.destination

        return (
          <Cell key={address}>
            <div className={mergeClassName(
              'h-full rounded-md border p-4 shadow lg:border-none',
              isOrigin && 'lg:mr-2',
              isDestination && 'lg:ml-2',
            )}
            >
              <Label>
                { isOrigin && t('components.move.addresses.movingFrom') }
                { isDestination && t('components.move.addresses.movingTo') }
              </Label>
              <Value className="whitespace-pre-wrap">
                { formatPlace(
                  data.address,
                  {
                    addressOnly: true,
                    compact: true,
                  },
                ).join('\n') }
              </Value>

              <div className="my-4">
                { (data?.nbStairs ?? 0) > 0
                  ? (
                    <>
                      <span className="font-body2 text-sm text-black">
                        { t('components.move.addresses.nbStairs') }
                      </span>
                      { ': ' }
                      { data.nbStairs }
                    </>
                    )
                  : (
                    <BooleanValue
                      value={false}
                      labelFalse={t('components.move.addresses.noStairs')}
                    />
                    ) }
              </div>

              { data.elevatorReservationRequired !== null && (
                <div className="my-4">
                  <BooleanValue
                    value={data.elevatorReservationRequired}
                    labelTrue={t('components.move.addresses.elevatorNeeded')}
                    labelFalse={t('components.move.addresses.elevatorNotNeeded')}
                  />
                </div>
              ) }

            </div>
          </Cell>
        )
      }) }
    </Row>
  )
}

export default AddressesSection
