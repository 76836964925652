import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import {
  QuotesView,
  useChangeQuotesViewAction,
} from '../../quotes'
import { useLocation } from '../../navigation'
import { type Move } from './moves.models'
import * as selectors from './moves.selectors'

export enum ACTION_TYPES {
  SET_MOVE_ID = 'SET_MOVE_ID',
  SET_IS_LOGGING_OUT = 'SET_IS_LOGGING_OUT',
}

export const setMoveId = (moveId: string | undefined) => ({
  type: ACTION_TYPES.SET_MOVE_ID as const,
  moveId,
})

export const setIsLoggingOut = () => ({
  type: ACTION_TYPES.SET_IS_LOGGING_OUT as const,
})

export type Actions = ReturnType<typeof setMoveId> | ReturnType<typeof setIsLoggingOut>

/**
 * remove the currently saved move
 */
export const useLogoutAction = () => {
  const intercom = useIntercom()
  const dispatch = useDispatch()
  const removeSavedId = useRemoveSavedMoveIdAction()
  return () => {
    intercom.hardShutdown()
    dispatch(setIsLoggingOut())
    removeSavedId()
    setTimeout(() => {
      document.location = '/'
    }, 100)
  }
}

/**
 * returns current move id based on routing
 * this is not using useParams on purpose since we want to read the move id outside of the route
 */
export const useMoveId = () => {
  const location = useLocation()
  return useMemo(() => {
    return location.pathname.match(/\/move\/([^/]+)/i)?.[1]
  }, [location.pathname])
}

/**
 * returned the last move of the user (saved in store)
 */
export const useSavedMoveId = () => {
  return useSelector(selectors.moveId)
}

/**
 * save the move id in store
 */
export const useSaveMoveAction = () => {
  const savedMoveId = useSavedMoveId()
  const isLoggingOut = useSelector(selectors.isLoggingOut)
  const dispatch = useDispatch()
  const changeQuotesView = useChangeQuotesViewAction()

  return (move: Move) => {
    if (isLoggingOut || move.id === savedMoveId) {
      return
    }
    dispatch(setMoveId(move.id))
    changeQuotesView(move.flexibleDate ? QuotesView.cheapest : QuotesView.best)
  }
}

/**
 * remove the currently saved move
 */
export const useRemoveSavedMoveIdAction = () => {
  const dispatch = useDispatch()
  return () => {
    dispatch(setMoveId(undefined))
  }
}
