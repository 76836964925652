import { useRef, useEffect } from 'react'

import appPackage from '../../../../package.json'
import { useConfig } from '../../config'
import { useMonitoring } from '../core'

const { version } = appPackage

const NewRelic: React.FC = () => {
  const initialized = useRef(false)
  const monitoringEnabled = !!useConfig('newRelic.applicationId')
  const { setApplicationVersion } = useMonitoring()

  useEffect(() => {
    if (initialized.current || !monitoringEnabled) {
      return
    }
    initialized.current = true
    setApplicationVersion(version)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default NewRelic
