import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import { useAnalytics } from '../../../modules/analytics'
import { useSavedMoveId } from '../../../modules/moves'
import { useNavigate } from '../../../modules/navigation'
import FullWidthPage from '../../../components/layout/FullWidthPage'
import FormCarousel from '../../../components/FormCarousel'
import DateTimeStep from './DateTimeStep'
import AddressesStep from './AddressesStep'
import MoveSizeStep from './MoveSizeStep'
import { type QuotesRequestFormValues } from './QuotesRequestPage.type'
import QuotesPreviewPage from '../QuotesPreviewPage'
import { useExperimentGroup } from '../../../modules/abTesting'
import ProgressBarDescription from './ProgressBarDescription'

const QuotesRequestPage: React.FC = () => {
  const { t } = useTranslation()
  const started = useRef(false)
  const { pushCustomPageView, pushStartQuotesRequestEvent } = useAnalytics()
  const [quotesRequest, setQuotesRequest] = useState<QuotesRequestFormValues>()
  const { navigate } = useNavigate()
  const moveId = useSavedMoveId()
  const hasMove = !!moveId

  const [currentSlide, setCurrentSlide] = useState(0)

  const slides = {
    addresses: <AddressesStep />,
    dateTime: <DateTimeStep />,
    moveSize: <MoveSizeStep />,
  }
  const nbSlides = Object.keys(slides).length

  const group = useExperimentGroup()

  const getProgress = () => {
    if (group === 'b') {
      return (currentSlide / nbSlides) * 100
    }

    const progress = (currentSlide / (nbSlides - 1)) * 100
    return Math.max(progress - 10, 5)
  }

  const getNbDots = () => {
    if (group === 'b') {
      return nbSlides + 1
    }
    return nbSlides
  }

  const getLabels = () => {
    if (group === 'b') {
      return [
        t('forms.quotesRequest.steps.addresses'),
        t('forms.quotesRequest.steps.dateTimeShort'),
        t('forms.quotesRequest.steps.moveSizeShort'),
        t('forms.quotesRequest.yourPrices'),
      ]
    }
  }

  if (!hasMove && !started.current) {
    started.current = true
    pushStartQuotesRequestEvent()
  }

  /**
   * redirects user to his move if any
   */
  useEffect(() => {
    if (hasMove) {
      navigate(Route.HomePage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMove])

  if (hasMove) {
    return null
  }

  /**
   * push tracking event
   */
  const onSlideChange = (step: string, index: number) => {
    setCurrentSlide(index)
    pushCustomPageView(`${Route.QuotesRequest}/${step}`)
  }

  if (quotesRequest) {
    return (
      <QuotesPreviewPage quotesRequest={quotesRequest} />
    )
  }

  return (
    <FullWidthPage>
      <FormCarousel<QuotesRequestFormValues>
        onSubmit={setQuotesRequest}
        slides={slides}
        contentWidth={1300}
        wrapperClassName="max-h-[calc(100dvh-120px)] md:max-h-[calc(100dvh-220px)] xl:mb-0 xl:max-h-[calc(100dvh-356px)]"
        onSlideChange={onSlideChange}
        progressBarProps={{
          progress: getProgress(),
          nbDots: getNbDots(),
          labels: getLabels(),
        }}
        progressBarDescription={<ProgressBarDescription />}
      />

    </FullWidthPage>
  )
}

export default QuotesRequestPage
