/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useAction, useActionWithPayload } from '../../graphQl/core/graphQl.hooks'
import { useCurrentMove } from '../../moves'
import * as mutations from './companyRatings.mutation'
import { type CompanyRatingPayload } from './companyRatings.types'

/**
 * rate moving company
 */
export const useRateCompanyBranchAction = () => {
  const move = useCurrentMove()

  return useActionWithPayload(
    useAction(mutations.rateCompanyBranch),
    (payload: CompanyRatingPayload) => ({
      moveId: move.id,
      payload,
    }),
  )
}
