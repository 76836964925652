import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import {
  QuotesView,
  useChangeQuotesViewAction,
  useQuotesView,
} from '../../../modules/quotes'
import { useCurrentMove } from '../../../modules/moves'
import { dateInTimezone, useDateFormatter } from '../../../utils/date'

const QuotesViewsDropdown: React.FC = () => {
  const { t } = useTranslation()
  const move = useCurrentMove()
  const changeView = useChangeQuotesViewAction()
  const quotesView = useQuotesView()
  const formatDate = useDateFormatter()

  const movingDate = formatDate(
    dateInTimezone(move.movingDate, move.originTimezone),
    { showYear: false, shortMonth: true })

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeView(event.target.value as QuotesView)
  }

  return (
    <div className="flex w-full flex-wrap items-center justify-center gap-3 md:justify-end">
      <div className="whitespace-nowrap font-sans text-neutral-500">
        { t('quotes.filters.sortBy') }
      </div>

      <TextField
        value={quotesView}
        onChange={onChange}
        size="small"
        select
      >
        <MenuItem
          value={QuotesView.cheapest}
        >
          <div className="font-sans text-sm">
            { t('quotes.filters.views.cheapest') }
          </div>
        </MenuItem>

        <MenuItem
          value={QuotesView.best}
        >
          <div className="font-sans text-sm">
            { t('quotes.filters.views.best') }
            { ' ' }
            { movingDate }
          </div>
        </MenuItem>

        <MenuItem
          value={QuotesView.bestRated}
        >
          <div className="font-sans text-sm">
            { t('quotes.filters.views.bestRated') }
          </div>
        </MenuItem>
      </TextField>
    </div>
  )
}

export default QuotesViewsDropdown
