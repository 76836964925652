import { type PublicCompanyBranch } from '../../../modules/companies'
import { useCompanyBranchName } from '../../../modules/companies'
import { mergeClassName } from '../../../utils/mergeClassName'

type TitleProps = {
  companyBranch: PublicCompanyBranch
  small?: boolean
}

const Title: React.FC<TitleProps> = ({
  companyBranch,
  small = false,
}) => {
  const companyName = useCompanyBranchName(companyBranch)
  return (
    <h1 className={mergeClassName(
      'mt-6 text-center text-2xl font-bold text-black',
      !small && 'xl:mb-2 xl:ml-8 xl:mt-8 xl:text-left',
    )}
    >
      { companyName }
    </h1>
  )
}

export default Title
