import { mergeClassName } from '../../utils/mergeClassName'

/* eslint-disable react/no-array-index-key */
export type ProgressBarProps = {
  nbDots?: number
  progress: number
  labels?: Array<React.ReactNode | undefined>
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  nbDots,
  progress,
  labels,
}) => {
  progress = Math.min(Math.max(progress, 0), 100)

  return (
    <div className="relative flex h-[30px] items-center">

      { nbDots && nbDots > 0 && Array.from({ length: nbDots }).map((_, index) => {
        const dotPercentage = (100 / (nbDots - 1)) * index
        const dotActive = Math.ceil(progress) >= Math.ceil(dotPercentage)

        const isFirst = index === 0
        const isLast = index === nbDots - 1
        const label = labels?.[index]

        return (
          <div
            key={index}
            className="absolute z-[2] ml-[-8px]"
            style={{ left: `${dotPercentage}%` }}
          >
            <div
              className={mergeClassName(
                'h-[16px] w-[16px] rounded-full bg-neutral-200',
                dotActive && 'bg-primary',
              )}
            />
            { label && (
              <div className={mergeClassName(
                'absolute text-xs xs:text-sm mt-2 w-[160px] font-body2 text-neutral-500 leading-[1]',
                dotActive && 'text-primary',
                !isFirst && '-ml-[72px] text-center',
                isLast && '-ml-[144px] text-right',
              )}
              >
                { label }
              </div>
            ) }
          </div>
        )
      }) }

      <div className="absolute left-0 h-[3px] w-full bg-neutral-200" />
      <div
        className="absolute left-0 h-[3px] bg-primary transition-width duration-300"
        style={{ width: `${progress}%` }}
      />
    </div>
  )
}

export default ProgressBar
