import { useTranslation } from 'react-i18next'

import {
  type MovePlace,
  type UpdateMovePayload,
  useCurrentMove,
  useSelectedQuote,
  useUpdateMoveAction,
} from '../../../modules/moves'
import {
  Modal,
  ModalDescription,
  ModalHeader,
  ModalTitle,
} from '../../../components/Modal'
import AddressesForm from '../../../components/forms/editmove/AddressesForm'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../components/ToastNotifications'

const ValidateAddressesModal: React.FC = () => {
  const { t } = useTranslation()
  const move = useCurrentMove()
  const selectedQuote = useSelectedQuote()
  const [updateMove] = useUpdateMoveAction()
  const formatError = useErrorFormatter()

  const onSubmit = async (data: UpdateMovePayload) => {
    try {
      await updateMove(data)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const isAddressComplete = (place: MovePlace) => {
    if (place.elevatorReservationRequired === null) {
      return false
    }

    if (!('country' in place.address)) {
      return false
    }
    const hasStreet = !!place.address.street
    const hasCity = !!place.address.city
    const hasRegion = !!place.address.region
    const hasCountry = !!place.address.country
    const hasPostalCode = !!place.address.postalCode

    return hasStreet && hasCity && hasRegion && hasCountry && hasPostalCode
  }

  const hasIncompleteAddresses = !isAddressComplete(move.origin) || !isAddressComplete(move.destination)
  const open = hasIncompleteAddresses && !selectedQuote?.customized

  return (
    <Modal
      open={open}
      style={{ maxWidth: 1200 }}
      showCloseIcon={false}
    >
      <ModalHeader>
        <ModalTitle>
          { t('pages.checkout.validateAddressModal.title') }
        </ModalTitle>
      </ModalHeader>

      <ModalDescription>
        <AddressesForm
          onSubmit={onSubmit}
          wide
        />
      </ModalDescription>
    </Modal>
  )
}

export default ValidateAddressesModal
