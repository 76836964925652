import { useTranslation } from 'react-i18next'

import {
  type UpdateMovePayload,
  useCurrentMove,
  useUpdateMoveAction,
} from '../../../modules/moves'
import { useNavigate } from '../../../modules/navigation'
import { type EditableSectionProps } from '../../EditableSections/editableSections.type'
import {
  Label,
  Value,
  Quantities,
  BooleanValue,
  Row,
  Cell,
} from '../../data'
import MoveContentForm from '../../forms/editmove/MoveContentForm'
import Route from '../../../app/Route.enum'
import { useErrorFormatter } from '../../errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../ToastNotifications'

const MoveContentSection: React.FC<EditableSectionProps> = ({
  isEditing,
  onClose,
  wide,
}) => {
  const { t } = useTranslation()
  const move = useCurrentMove()
  const [updateMove] = useUpdateMoveAction()
  const { navigate } = useNavigate()
  const formatError = useErrorFormatter()

  const onSubmit = async (data: UpdateMovePayload) => {
    try {
      await updateMove(data)
      onClose()
      navigate(Route.Move)
      successNotification((
        <>
          { t('components.move.moveContent.success') }
          <div className="font-bold">
            { t('components.move.quoteRecalculated') }
          </div>
        </>
      ))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (isEditing) {
    return (
      <MoveContentForm
        onSubmit={onSubmit}
        onCancel={onClose}
        wide={wide}
      />
    )
  }

  return (
    <Row>
      <Cell>
        <Label>
          { t('components.move.moveContent.residenceType') }
        </Label>
        <Value>
          { t(`residenceType.${move.residenceType}.title`) }
        </Value>

        <div className="mt-4">
          <Label>
            { t('components.move.moveContent.nbBoxes') }
          </Label>
          <Value>
            { move.nbBoxes
              ? (
                <>{ t(`nbBoxes.${move.nbBoxes}.title`) }
                  { ' ' }
                  <small>({ t('components.move.moveContent.approximately') })</small>
                </>
                )
              : (
                <div className="my-4">
                  <BooleanValue
                    value={false}
                    labelFalse={t('components.move.moveContent.noBoxes')}
                  />
                </div>
                ) }
          </Value>
        </div>

        <div className="mt-4">
          <Label>
            { t('components.move.moveContent.furniture') }
          </Label>
          <Value>
            { move.furnitureRatio
              ? t(`furnitureRatio.${move.furnitureRatio}.title`)
              : (
                <div className="my-4">
                  <BooleanValue
                    value={false}
                    labelFalse={t('components.move.moveContent.noFurniture')}
                  />
                </div>
                ) }
          </Value>
        </div>
      </Cell>

      <Cell>

        { (move.residenceRooms?.length ?? 0) > 0 && (
          <div className="mb-4">
            <Label>{ t('rooms.title') }</Label>
            <Quantities>

              { move.residenceRooms?.map(({ key, value }) => ({
                key: t(`rooms.${key}.title`, { count: value }),
                value,
              })) }

            </Quantities>
          </div>
        ) }

        <Label>{ t('specialItems.title') }</Label>
        { (move.specialItems?.length ?? 0) > 0
          ? (
            <Quantities>
              { move.specialItems?.map(({ key, value }) => ({
                key: t(`specialItems.${key}.title`, { count: value }),
                value,
              })) }
            </Quantities>
            )
          : (
            <div className="my-4">
              <BooleanValue
                value={false}
                labelFalse={t('components.move.moveContent.noSpecialItems')}
              />
            </div>
            ) }
      </Cell>
    </Row>
  )
}

export default MoveContentSection
