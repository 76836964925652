import { useInfiniteScrollData } from '../../graphQl'
import { type CompanyRating } from './companyRatings.models'
import * as queries from './companyRatings.queries'
import { RatingSorting } from './companyRatings.enums'
import { getCompanyRatingQuery } from './companyRatings.utils'

export const useCompanyBranchRatings = (
  companyBranchId: string,
  sorting: RatingSorting = RatingSorting.MostRecent,
) => {
  const {
    fetchMore: fetchMoreCompanyBranchRatings,
    ...data
  } = useInfiniteScrollData<CompanyRating>(
    queries.getCompanyBranchRatings, {
      companyBranchId,
      query: getCompanyRatingQuery(sorting),
    })

  return {
    fetchMoreCompanyBranchRatings,
    ...data,
  }
}
