import { useTranslation } from 'react-i18next'
import { Transition } from '@headlessui/react'

import { type PreliminaryQuotesResponse } from '../../../modules/quotes'
import { useCreateMoveAction, useSaveMoveAction } from '../../../modules/moves'
import { useNavigate, usePathGenerator, useQueryString } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import { mergeClassName } from '../../../utils/mergeClassName'
import { Heading, Subtitle } from '../../../components/typography'
import RegisterForm from '../../../components/forms/RegisterForm'
import { type RegisterFormValues } from '../../../components/forms/RegisterForm/RegisterForm.type'
import { formatMoveRequest } from '../QuotesRequestPage/utils/formatRequest'
import { type QuotesRequestFormValues } from '../QuotesRequestPage/QuotesRequestPage.type'
import PreliminaryQuotePreview from './PreliminaryQuotePreview'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../components/ToastNotifications'

type QuotesPreviewResultsProps = {
  requestFormValues: QuotesRequestFormValues
  results: PreliminaryQuotesResponse
}

const QuotesPreviewResults: React.FC<QuotesPreviewResultsProps> = ({
  requestFormValues,
  results,
}) => {
  const { t } = useTranslation()
  const [createMove] = useCreateMoveAction()
  const saveMove = useSaveMoveAction()
  const { navigate } = useNavigate()
  const generatePath = usePathGenerator()
  const formatError = useErrorFormatter()
  const queryString = useQueryString()

  const handleCreateMove = async (userFormValues: RegisterFormValues) => {
    const payload = formatMoveRequest({
      requestFormValues,
      userFormValues,
      queryString,
    })
    try {
      const move = await createMove(payload)
      if (move) {
        saveMove(move)
        navigate(generatePath(Route.Move, { moveId: move.id }))
      }
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <>
      <Heading variant="h1" className="text-center">
        { t(
          'pages.quotesRequest.preliminaryQuotes.online.title',
          { count: results.totalQuotes },
        ) }
      </Heading>
      <Subtitle className="lg:text-center">
        { t(
          'pages.quotesRequest.preliminaryQuotes.online.subtitle',
          { count: results.totalQuotes },
        ) }
      </Subtitle>

      <div className="flex w-full justify-center">
        <div
          className={mergeClassName(
            'md:max-w-auto relative my-8 h-[375px] w-full max-w-[500px] pt-2 md:h-[245px] lg:my-16',
            results.totalQuotes === 2 && 'h-[240px] md:h-[160px]',
            results.totalQuotes === 1 && 'flex justify-center h-auto md:h-auto',
          )}
        >
          <Transition.Root
            show
            appear
          >
            { results.preliminaryQuotes.map((preliminaryQuote, index) => (
              <Transition.Child
                key={preliminaryQuote.id}
                enter={mergeClassName(
                  'transition-all ease-linear duration-400',
                  index === 0 && 'delay-0',
                  index === 1 && 'delay-100',
                  index === 2 && 'delay-300',
                )}
                enterFrom="scale-90 opacity-0"
                enterTo="scale-100 opacity-100"
              >
                <div
                  className={mergeClassName(
                    'absolute',
                    index === 0 && 'left-0 md:left-1/2 md:ml-[-300px] md:scale-[1.1]',
                    index === 1 && 'right-[-20px] md:right-auto md:left-1/2 md:ml-[45px] mt-[120px] md:mt-[40px] scale-[.85]',
                    index === 2 && 'left-0 md:left-1/2 md:ml-[-210px] mt-[220px] md:mt-[140px] scale-[.65]',
                    results.totalQuotes === 1 && 'static m-0 md:m-0 scale-[1.1]',
                  )}
                >
                  <PreliminaryQuotePreview
                    preliminaryQuote={preliminaryQuote}
                  />
                </div>
              </Transition.Child>
            )) }

            { results.totalQuotes > 3 && (
              <Transition.Child
                enter="transition-opacity ease-linear duration-400 delay-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
              >
                <div className="absolute bottom-[10px] right-[30px] font-sans text-lg text-neutral-500 md:bottom-auto md:left-1/2 md:right-auto md:ml-[90px] md:mt-[185px]">
                  { (t(
                    'pages.quotesRequest.preliminaryQuotes.online.nbMore',
                    { count: results.totalQuotes - 3 },
                  )) }
                </div>
              </Transition.Child>
            ) }

          </Transition.Root>
        </div>
      </div>

      <Heading variant="h2" className="text-center">
        { t('pages.quotesRequest.preliminaryQuotes.online.register.title') }
      </Heading>
      <Subtitle className="lg:text-center">
        { t('pages.quotesRequest.preliminaryQuotes.online.register.subtitle') }
      </Subtitle>

      <RegisterForm
        onSubmit={handleCreateMove}
      />
    </>
  )
}

export default QuotesPreviewResults
