import { useTranslation } from 'react-i18next'

import { type DataOptions, useLazyData } from '../../graphQl'
import {
  type PreliminaryQuotesRequestPayload,
  type MoveDetails,
  type MoveSizeEstimate,
  type MoveSizeDetails,
} from './quotes.types'
import { estimateMoveSize, estimatePreliminaryMoveSize } from './estimators/moveEstimator'
import * as queries from './quotes.queries'
import { type PreliminaryQuotesResponse } from './quotes.models'
import { useAnalytics } from '../../analytics'
import { ChargeDescription } from '../../invoices'

/**
 * fetch preliminary quotes (lazy query)
 */
export const usePreliminaryQuotes = (dataOptions?: DataOptions) => {
  const { fetch, ...data } = useLazyData<PreliminaryQuotesResponse>(
    queries.getPreliminaryQuotes,
    dataOptions,
  )
  const { pushQuotesRequestedEvent } = useAnalytics()

  const fetchPreliminaryQuotes = async (
    preliminaryQuotesRequest?: PreliminaryQuotesRequestPayload) => {
    try {
      const response = await fetch({ preliminaryQuotesRequest })

      /* tracking "online" quotes */

      const nbQuotesReceived = response.totalQuotes ?? 0
      const cheapestPriceReceived = response.preliminaryQuotes?.[0]?.price
      const fromCity = response.resolvedOriginAddress?.city
      const fromRegion = response.resolvedOriginAddress?.region
      const toCity = response.resolvedDestinationAddress?.city
      const toRegion = response.resolvedDestinationAddress?.region

      pushQuotesRequestedEvent({
        nbQuotesReceived,
        cheapestPriceReceived,
        fromCity,
        fromRegion,
        toCity,
        toRegion,
      })

      return response
    } catch (error: any) {
      /* tracking "agent" quotes */

      pushQuotesRequestedEvent({
        nbQuotesReceived: 0,
      })

      throw new Error(error.message)
    }
  }

  return {
    fetchPreliminaryQuotes,
    ...data,
  }
}

/**
 * estimate preliminary move size based move entity
 */
export const usePreliminaryMoveSizeEstimate = (moveDetails: MoveDetails): MoveSizeEstimate => {
  return estimatePreliminaryMoveSize(moveDetails)
}

/**
 * estimate move size based on form input
 */
export const useMoveSizeEstimate = () => {
  return (move: MoveSizeDetails): MoveSizeEstimate => estimateMoveSize(move)
}

/*
 * the "item" value is based on the pricing description
 */
export const useQuoteItemValueFormatter = () => {
  const { t } = useTranslation()

  return (description: ChargeDescription, value?: string) => {
    switch (description) {
      case ChargeDescription.movingLabour:
      case ChargeDescription.packingLabour:
      case ChargeDescription.travelLabour:
      case ChargeDescription.transportLabour:
      case ChargeDescription.additionalChargesStairs:
      {
        const nbMen = parseInt(value ?? '0')
        if (nbMen === 0) {
          return
        }
        return t('quotes.pricing.item.man', { count: nbMen })
      }
      case ChargeDescription.travelTruckFeePerKilometer:
      case ChargeDescription.transportTruckFeePerKilometer:
      case ChargeDescription.additionalChargesOvernight:
      {
        const nbTrucks = parseInt(value ?? '0')
        if (nbTrucks < 2) {
          return
        }
        return t('quotes.pricing.item.truck', { count: nbTrucks })
      }
      case ChargeDescription.fixedTruckFee:
      {
        if (!value) {
          return
        }
        return t(`trucks.${value}.title`)
      }
      case ChargeDescription.additionalChargesSpecialServices:
      {
        if (!value) {
          return
        }
        return t(`specialServices.${value}.title`)
      }
      case ChargeDescription.additionalChargesSpecialItems:
      {
        if (!value) {
          return
        }
        return t(`specialItems.${value}.title`)
      }
      case ChargeDescription.protectiveMaterialProduct:
      {
        if (!value) {
          return
        }
        return t(`protectiveMaterial.${value}.title`)
      }
      default:
        return value
    }
  }
}
