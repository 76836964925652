import { useTranslation } from 'react-i18next'

import Container, { PageContent } from '../../../components/Container'
import Spinner from '../../../components/Spinner'
import { type QuotesRequestFormValues } from '../QuotesRequestPage/QuotesRequestPage.type'
import { usePreliminaryQuotes } from '../../../modules/quotes'
import { useEffectOnce } from '../../../utils/useEffectOnce'
import { formatPreliminaryQuotesRequest } from '../QuotesRequestPage/utils/formatRequest'
import QuotesPreviewResults from './QuotesPreviewResults'
import QuotesPreviewNoResults from './QuotesPreviewNoResults'
import QuotesPreviewAgent from './QuotesPreviewAgent'
import FullWidthPage from '../../../components/layout/FullWidthPage'
import { useState } from 'react'

type QuotesPreviewProps = {
  quotesRequest: QuotesRequestFormValues
}

const QuotesPreviewPage: React.FC<QuotesPreviewProps> = ({
  quotesRequest,
}) => {
  const { t } = useTranslation()
  const { fetchPreliminaryQuotes, loading, data, error, called } = usePreliminaryQuotes()
  const [frontendError, setFrontendError] = useState(false)

  const succeeded = called && !loading && !error
  const nbQuotes = data?.preliminaryQuotes?.length ?? 0

  const hasData = nbQuotes > 0

  useEffectOnce(() => {
    try {
      fetchPreliminaryQuotes(
        formatPreliminaryQuotesRequest(quotesRequest),
      ).catch(console.error)
    } catch (error) {
      setFrontendError(true)
      console.error(error)
    }
  })

  return (
    <FullWidthPage>
      <Container>
        <PageContent>

          { loading && (
            <div className="my-24 flex flex-col items-center justify-center gap-8">
              <Spinner />
              <div className="text-center font-body2 text-2xl uppercase leading-tight text-slate-400">
                { t('pages.quotesRequest.fetchingQuotes') }
              </div>
            </div>
          ) }

          { succeeded && hasData && data && (
            <QuotesPreviewResults
              requestFormValues={quotesRequest}
              results={data}
            />
          ) }

          { succeeded && !hasData && (
            <QuotesPreviewNoResults originAddress={data?.resolvedOriginAddress} />
          ) }

          { (error ?? frontendError) && (
            <QuotesPreviewAgent
              requestFormValues={quotesRequest}
            />
          ) }

        </PageContent>
      </Container>
    </FullWidthPage>
  )
}

export default QuotesPreviewPage
