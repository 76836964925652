import { useConfig } from '../../config'
import { useDebugExperimentGroup, useExperimentGroup } from '../core'

const Debug: React.FC = () => {
  const debug = !(useConfig('isProduction') ?? true)
  const group = useExperimentGroup()
  const { nextGroup } = useDebugExperimentGroup()

  if (!debug || !group) {
    return null
  }

  return (
    <div
      className="flex size-[35px] cursor-pointer items-center justify-center rounded border bg-white p-2 text-neutral-500 shadow"
      onClick={() => { nextGroup() }}
    >
      { group }
    </div>
  )
}

export default Debug
