import { useEffect, useRef } from 'react'
import ReactFacebookPixel from 'react-facebook-pixel'

import { useConfig } from '../../config'
import { useConsent } from '../../consent/core/consent.hooks'
import { ConsentCategory } from '../../consent'

const FacebookPixel: React.FC = () => {
  const initialized = useRef(false)
  const accessGranted = useRef(true)
  const pixelId = useConfig('facebook.pixelId')
  const debug = useConfig<boolean>('analytics.debug') ?? false
  const analyticsConsent = useConsent(ConsentCategory.Analytics)

  /**
   * initialize Facebook pixel on load
   */
  useEffect(() => {
    if (initialized.current || !pixelId) {
      return
    }
    initialized.current = true

    if (debug) {
      console.log('[Facebook Pixel debug] Initialize')
    }

    ReactFacebookPixel.init(
      pixelId,
      undefined,
      {
        autoConfig: true,
        debug,
      })

    if (!analyticsConsent) {
      if (debug) {
        console.log('[Facebook Pixel debug] Revoke consent')
      }
      accessGranted.current = false
      ReactFacebookPixel.revokeConsent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pixelId, debug])

  /**
   * grand or revoke consent when user update his choices
   */
  useEffect(() => {
    if (
      !initialized.current ||
      !pixelId ||
      accessGranted.current === analyticsConsent
    ) {
      return
    }
    if (analyticsConsent) {
      if (debug) {
        console.log('[Facebook Pixel debug] Grant consent')
      }
      accessGranted.current = true
      ReactFacebookPixel.grantConsent()
    } else {
      if (debug) {
        console.log('[Facebook Pixel debug] Revoke consent')
      }
      accessGranted.current = false
      ReactFacebookPixel.revokeConsent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsConsent])

  return null
}

export default FacebookPixel
