import { Trans, useTranslation } from 'react-i18next'

import { useCurrentMove, usePrepareMoveBalancePaymentAction } from '../../../modules/moves'
import { Heading, Subtitle } from '../../../components/typography'
import FullWidthPage from '../../../components/layout/FullWidthPage'
import { PageContent } from '../../../components/Container'
import Price from '../../../components/Price'
import Spinner from '../../../components/Spinner'
import { Error404Page } from '../../errors'
import PaymentPageContent from '../PaymentPageContent'

const PayBalancePAge: React.FC = () => {
  const { t } = useTranslation()
  const move = useCurrentMove()
  const [prepareMoveBalancePayment, {
    called,
    loading,
    error,
  }] = usePrepareMoveBalancePaymentAction()

  if (!error && !called && loading) {
    return (
      <FullWidthPage>
        <Spinner floating />
      </FullWidthPage>
    )
  }

  if (!error && called && !loading && !move.paymentProfile?.paymentRequest) {
    return <Error404Page />
  }

  return (
    <FullWidthPage>
      <PageContent>

        <div className="pt-4 text-center">
          <Heading variant="h1">
            { t('pages.move.payBalance.title') }
          </Heading>
          { move.paymentProfile?.paymentRequest && (
            <Subtitle className="mb-6 lg:text-center">
              <Trans i18nKey="pages.move.payBalance.subtitle">
                <Price
                  amount={move.paymentProfile.paymentRequest.amount}
                  compact
                  className="font-bold"
                />
              </Trans>
            </Subtitle>
          ) }

          <PaymentPageContent
            move={move}
            paymentRequest={move.paymentProfile?.paymentRequest}
            preparePayment={{
              prepare: prepareMoveBalancePayment,
              called,
              loading,
              error,
            }}
          />

        </div>

      </PageContent>
    </FullWidthPage>
  )
}

export default PayBalancePAge
