import { type ToastContent, type ToastOptions, toast } from 'react-toastify'

import { error } from '../../modules/monitoring'

const ONE_SEC_MS = 1000
const TEN_SECS_MS = 10 * ONE_SEC_MS

export const infoNotification = (message: ToastContent, options?: ToastOptions) => {
  toast.info(message, options)
}

export const successNotification = (message: ToastContent, options?: ToastOptions) => {
  toast.success(message, options)
}

export const warningNotification = (message: ToastContent, options?: ToastOptions) => {
  toast.warning(message, {
    autoClose: options?.autoClose ?? TEN_SECS_MS,

  })
}

export const errorNotification = (message: ToastContent, options?: ToastOptions) => {
  error(message as any)
  toast.error(message, {
    autoClose: options?.autoClose ?? TEN_SECS_MS,
  })
}
